@charset "UTF-8";
/* Commun aux minisites 5/6/7 */
/* Déclinaison couleur 1 */
/* Déclinaison couleur 2 */
/* Déclinaison couleur 3 */
/* Déclinaison couleur 4 */
/* Déclinaison couleur 5 */
/* Déclinaison couleur 6 */
/* Déclinaison couleur 7 */
/* Déclinaison couleur 8 */
/* Déclinaison couleur 9 */
/* Déclinaison couleur 10 */
body.color_v1 .couleur1 {
  color: #EA624A; }

body.color_v1 .couleur1_hover:hover {
  color: #EA624A; }

body.color_v1 .couleur1_bg {
  background-color: #EA624A; }

body.color_v1 .button_color1 {
  color: #EA624A;
  border: 1px solid #EA624A; }
  body.color_v1 .button_color1:focus, body.color_v1 .button_color1:hover {
    color: #fff;
    border: 1px solid #EA624A;
    background-color: #EA624A; }

body.color_v1 .couleur1_hover:hover .rond_inside {
  color: #EA624A !important; }

body.color_v1 .couleur1_bg_hover:hover .rond_inside {
  background-color: #EA624A !important; }

body.color_v1 .border_color1 {
  border-color: #EA624A; }

body.color_v1 .border_top_color1 {
  border-top-color: #EA624A; }

body.color_v1 .button_color1_back {
  display: inline-block;
  padding: 0 10px;
  color: #fff;
  border: 1px solid #EA624A;
  background-color: #EA624A; }
  body.color_v1 .button_color1_back:hover {
    border: 1px solid #EA624A;
    color: #EA624A;
    background-color: #fff; }

body.color_v1 .couleur2 {
  color: #F9E1DC; }

body.color_v1 .couleur2_hover:hover {
  color: #F9E1DC; }

body.color_v1 .couleur2_bg {
  background-color: #F9E1DC; }

body.color_v1 .button_color2 {
  color: #F9E1DC;
  border: 1px solid #F9E1DC; }
  body.color_v1 .button_color2:focus, body.color_v1 .button_color2:hover {
    color: #fff;
    border: 1px solid #F9E1DC;
    background-color: #F9E1DC; }

body.color_v1 .couleur2_hover:hover .rond_inside {
  color: #F9E1DC !important; }

body.color_v1 .couleur2_bg_hover:hover .rond_inside {
  background-color: #F9E1DC !important; }

body.color_v1 .border_color2 {
  border-color: #F9E1DC; }

body.color_v1 .border_top_color2 {
  border-top-color: #F9E1DC; }

body.color_v1 .button_color2_back {
  display: inline-block;
  padding: 0 10px;
  color: #fff;
  border: 1px solid #F9E1DC;
  background-color: #F9E1DC; }
  body.color_v1 .button_color2_back:hover {
    border: 1px solid #F9E1DC;
    color: #F9E1DC;
    background-color: #fff; }

body.color_v1 .couleur3 {
  color: #FEF9FA; }

body.color_v1 .couleur3_hover:hover {
  color: #FEF9FA; }

body.color_v1 .couleur3_bg {
  background-color: #FEF9FA; }

body.color_v1 .button_color3 {
  color: #FEF9FA;
  border: 1px solid #FEF9FA; }
  body.color_v1 .button_color3:focus, body.color_v1 .button_color3:hover {
    color: #fff;
    border: 1px solid #FEF9FA;
    background-color: #FEF9FA; }

body.color_v1 .couleur3_hover:hover .rond_inside {
  color: #FEF9FA !important; }

body.color_v1 .couleur3_bg_hover:hover .rond_inside {
  background-color: #FEF9FA !important; }

body.color_v1 .border_color3 {
  border-color: #FEF9FA; }

body.color_v1 .border_top_color3 {
  border-top-color: #FEF9FA; }

body.color_v1 .button_color3_back {
  display: inline-block;
  padding: 0 10px;
  color: #fff;
  border: 1px solid #FEF9FA;
  background-color: #FEF9FA; }
  body.color_v1 .button_color3_back:hover {
    border: 1px solid #FEF9FA;
    color: #FEF9FA;
    background-color: #fff; }

body.color_v2 .couleur1 {
  color: #1A1F2B; }

body.color_v2 .couleur1_hover:hover {
  color: #1A1F2B; }

body.color_v2 .couleur1_bg {
  background-color: #1A1F2B; }

body.color_v2 .button_color1 {
  color: #1A1F2B;
  border: 1px solid #1A1F2B; }
  body.color_v2 .button_color1:focus, body.color_v2 .button_color1:hover {
    color: #fff;
    border: 1px solid #1A1F2B;
    background-color: #1A1F2B; }

body.color_v2 .couleur1_hover:hover .rond_inside {
  color: #1A1F2B !important; }

body.color_v2 .couleur1_bg_hover:hover .rond_inside {
  background-color: #1A1F2B !important; }

body.color_v2 .border_color1 {
  border-color: #1A1F2B; }

body.color_v2 .border_top_color1 {
  border-top-color: #1A1F2B; }

body.color_v2 .button_color1_back {
  color: #fff;
  border: 1px solid #1A1F2B;
  background-color: #1A1F2B; }
  body.color_v2 .button_color1_back:hover {
    border: 1px solid #1A1F2B;
    color: #1A1F2B;
    background-color: #fff; }

body.color_v2 .couleur2 {
  color: #F0F3FD; }

body.color_v2 .couleur2_hover:hover {
  color: #F0F3FD; }

body.color_v2 .couleur2_bg {
  background-color: #F0F3FD; }

body.color_v2 .button_color2 {
  color: #F0F3FD;
  border: 1px solid #F0F3FD; }
  body.color_v2 .button_color2:focus, body.color_v2 .button_color2:hover {
    color: #fff;
    border: 1px solid #F0F3FD;
    background-color: #F0F3FD; }

body.color_v2 .couleur2_hover:hover .rond_inside {
  color: #F0F3FD !important; }

body.color_v2 .couleur2_bg_hover:hover .rond_inside {
  background-color: #F0F3FD !important; }

body.color_v2 .border_color2 {
  border-color: #F0F3FD; }

body.color_v2 .border_top_color2 {
  border-top-color: #F0F3FD; }

body.color_v2 .button_color2_back {
  color: #fff;
  border: 1px solid #F0F3FD;
  background-color: #F0F3FD; }
  body.color_v2 .button_color2_back:hover {
    border: 1px solid #F0F3FD;
    color: #F0F3FD;
    background-color: #fff; }

body.color_v2 .couleur3 {
  color: #F6F5F7; }

body.color_v2 .couleur3_hover:hover {
  color: #F6F5F7; }

body.color_v2 .couleur3_bg {
  background-color: #F6F5F7; }

body.color_v2 .button_color3 {
  color: #F6F5F7;
  border: 1px solid #F6F5F7; }
  body.color_v2 .button_color3:focus, body.color_v2 .button_color3:hover {
    color: #fff;
    border: 1px solid #F6F5F7;
    background-color: #F6F5F7; }

body.color_v2 .couleur3_hover:hover .rond_inside {
  color: #F6F5F7 !important; }

body.color_v2 .couleur3_bg_hover:hover .rond_inside {
  background-color: #F6F5F7 !important; }

body.color_v2 .border_color3 {
  border-color: #F6F5F7; }

body.color_v2 .border_top_color3 {
  border-top-color: #F6F5F7; }

body.color_v2 .button_color3_back {
  color: #fff;
  border: 1px solid #F6F5F7;
  background-color: #F6F5F7; }
  body.color_v2 .button_color3_back:hover {
    border: 1px solid #F6F5F7;
    color: #F6F5F7;
    background-color: #fff; }

body.color_v3 .couleur1 {
  color: #0062F2; }

body.color_v3 .couleur1_hover:hover {
  color: #0062F2; }

body.color_v3 .couleur1_bg {
  background-color: #0062F2; }

body.color_v3 .button_color1 {
  color: #0062F2;
  border: 1px solid #0062F2; }
  body.color_v3 .button_color1:focus, body.color_v3 .button_color1:hover {
    color: #fff;
    border: 1px solid #0062F2;
    background-color: #0062F2; }

body.color_v3 .couleur1_hover:hover .rond_inside {
  color: #0062F2 !important; }

body.color_v3 .couleur1_bg_hover:hover .rond_inside {
  background-color: #0062F2 !important; }

body.color_v3 .border_color1 {
  border-color: #0062F2; }

body.color_v3 .border_top_color1 {
  border-top-color: #0062F2; }

body.color_v3 .button_color1_back {
  color: #fff;
  border: 1px solid #0062F2;
  background-color: #0062F2; }
  body.color_v3 .button_color1_back:hover {
    border: 1px solid #0062F2;
    color: #0062F2;
    background-color: #fff; }

body.color_v3 .couleur2 {
  color: #E6EEFE; }

body.color_v3 .couleur2_hover:hover {
  color: #E6EEFE; }

body.color_v3 .couleur2_bg {
  background-color: #E6EEFE; }

body.color_v3 .button_color2 {
  color: #E6EEFE;
  border: 1px solid #E6EEFE; }
  body.color_v3 .button_color2:focus, body.color_v3 .button_color2:hover {
    color: #fff;
    border: 1px solid #E6EEFE;
    background-color: #E6EEFE; }

body.color_v3 .couleur2_hover:hover .rond_inside {
  color: #E6EEFE !important; }

body.color_v3 .couleur2_bg_hover:hover .rond_inside {
  background-color: #E6EEFE !important; }

body.color_v3 .border_color2 {
  border-color: #E6EEFE; }

body.color_v3 .border_top_color2 {
  border-top-color: #E6EEFE; }

body.color_v3 .button_color2_back {
  color: #fff;
  border: 1px solid #E6EEFE;
  background-color: #E6EEFE; }
  body.color_v3 .button_color2_back:hover {
    border: 1px solid #E6EEFE;
    color: #E6EEFE;
    background-color: #fff; }

body.color_v3 .couleur3 {
  color: #F9F4F4; }

body.color_v3 .couleur3_hover:hover {
  color: #F9F4F4; }

body.color_v3 .couleur3_bg {
  background-color: #F9F4F4; }

body.color_v3 .button_color3 {
  color: #F9F4F4;
  border: 1px solid #F9F4F4; }
  body.color_v3 .button_color3:focus, body.color_v3 .button_color3:hover {
    color: #fff;
    border: 1px solid #F9F4F4;
    background-color: #F9F4F4; }

body.color_v3 .couleur3_hover:hover .rond_inside {
  color: #F9F4F4 !important; }

body.color_v3 .couleur3_bg_hover:hover .rond_inside {
  background-color: #F9F4F4 !important; }

body.color_v3 .border_color3 {
  border-color: #F9F4F4; }

body.color_v3 .border_top_color3 {
  border-top-color: #F9F4F4; }

body.color_v3 .button_color3_back {
  color: #fff;
  border: 1px solid #F9F4F4;
  background-color: #F9F4F4; }
  body.color_v3 .button_color3_back:hover {
    border: 1px solid #F9F4F4;
    color: #F9F4F4;
    background-color: #fff; }

body.color_v4 .couleur1 {
  color: #FEC14F; }

body.color_v4 .couleur1_hover:hover {
  color: #FEC14F; }

body.color_v4 .couleur1_bg {
  background-color: #FEC14F; }

body.color_v4 .button_color1 {
  color: #FEC14F;
  border: 1px solid #FEC14F; }
  body.color_v4 .button_color1:focus, body.color_v4 .button_color1:hover {
    color: #fff;
    border: 1px solid #FEC14F;
    background-color: #FEC14F; }

body.color_v4 .couleur1_hover:hover .rond_inside {
  color: #FEC14F !important; }

body.color_v4 .couleur1_bg_hover:hover .rond_inside {
  background-color: #FEC14F !important; }

body.color_v4 .border_color1 {
  border-color: #FEC14F; }

body.color_v4 .border_top_color1 {
  border-top-color: #FEC14F; }

body.color_v4 .button_color1_back {
  color: #fff;
  border: 1px solid #FEC14F;
  background-color: #FEC14F; }
  body.color_v4 .button_color1_back:hover {
    border: 1px solid #FEC14F;
    color: #FEC14F;
    background-color: #fff; }

body.color_v4 .couleur2 {
  color: #FFFDED; }

body.color_v4 .couleur2_hover:hover {
  color: #FFFDED; }

body.color_v4 .couleur2_bg {
  background-color: #FFFDED; }

body.color_v4 .button_color2 {
  color: #FFFDED;
  border: 1px solid #FFFDED; }
  body.color_v4 .button_color2:focus, body.color_v4 .button_color2:hover {
    color: #fff;
    border: 1px solid #FFFDED;
    background-color: #FFFDED; }

body.color_v4 .couleur2_hover:hover .rond_inside {
  color: #FFFDED !important; }

body.color_v4 .couleur2_bg_hover:hover .rond_inside {
  background-color: #FFFDED !important; }

body.color_v4 .border_color2 {
  border-color: #FFFDED; }

body.color_v4 .border_top_color2 {
  border-top-color: #FFFDED; }

body.color_v4 .button_color2_back {
  color: #fff;
  border: 1px solid #FFFDED;
  background-color: #FFFDED; }
  body.color_v4 .button_color2_back:hover {
    border: 1px solid #FFFDED;
    color: #FFFDED;
    background-color: #fff; }

body.color_v4 .couleur3 {
  color: #F6F8FA; }

body.color_v4 .couleur3_hover:hover {
  color: #F6F8FA; }

body.color_v4 .couleur3_bg {
  background-color: #F6F8FA; }

body.color_v4 .button_color3 {
  color: #F6F8FA;
  border: 1px solid #F6F8FA; }
  body.color_v4 .button_color3:focus, body.color_v4 .button_color3:hover {
    color: #fff;
    border: 1px solid #F6F8FA;
    background-color: #F6F8FA; }

body.color_v4 .couleur3_hover:hover .rond_inside {
  color: #F6F8FA !important; }

body.color_v4 .couleur3_bg_hover:hover .rond_inside {
  background-color: #F6F8FA !important; }

body.color_v4 .border_color3 {
  border-color: #F6F8FA; }

body.color_v4 .border_top_color3 {
  border-top-color: #F6F8FA; }

body.color_v4 .button_color3_back {
  color: #fff;
  border: 1px solid #F6F8FA;
  background-color: #F6F8FA; }
  body.color_v4 .button_color3_back:hover {
    border: 1px solid #F6F8FA;
    color: #F6F8FA;
    background-color: #fff; }

body.color_v5 .couleur1 {
  color: #4646F4; }

body.color_v5 .couleur1_hover:hover {
  color: #4646F4; }

body.color_v5 .couleur1_bg {
  background-color: #4646F4; }

body.color_v5 .button_color1 {
  color: #4646F4;
  border: 1px solid #4646F4; }
  body.color_v5 .button_color1:focus, body.color_v5 .button_color1:hover {
    color: #fff;
    border: 1px solid #4646F4;
    background-color: #4646F4; }

body.color_v5 .couleur1_hover:hover .rond_inside {
  color: #4646F4 !important; }

body.color_v5 .couleur1_bg_hover:hover .rond_inside {
  background-color: #4646F4 !important; }

body.color_v5 .border_color1 {
  border-color: #4646F4; }

body.color_v5 .border_top_color1 {
  border-top-color: #4646F4; }

body.color_v5 .button_color1_back {
  color: #fff;
  border: 1px solid #4646F4;
  background-color: #4646F4; }
  body.color_v5 .button_color1_back:hover {
    border: 1px solid #4646F4;
    color: #4646F4;
    background-color: #fff; }

body.color_v5 .couleur2 {
  color: #F6F6F6; }

body.color_v5 .couleur2_hover:hover {
  color: #F6F6F6; }

body.color_v5 .couleur2_bg {
  background-color: #F6F6F6; }

body.color_v5 .button_color2 {
  color: #F6F6F6;
  border: 1px solid #F6F6F6; }
  body.color_v5 .button_color2:focus, body.color_v5 .button_color2:hover {
    color: #fff;
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6; }

body.color_v5 .couleur2_hover:hover .rond_inside {
  color: #F6F6F6 !important; }

body.color_v5 .couleur2_bg_hover:hover .rond_inside {
  background-color: #F6F6F6 !important; }

body.color_v5 .border_color2 {
  border-color: #F6F6F6; }

body.color_v5 .border_top_color2 {
  border-top-color: #F6F6F6; }

body.color_v5 .button_color2_back {
  color: #fff;
  border: 1px solid #F6F6F6;
  background-color: #F6F6F6; }
  body.color_v5 .button_color2_back:hover {
    border: 1px solid #F6F6F6;
    color: #F6F6F6;
    background-color: #fff; }

body.color_v5 .couleur3 {
  color: #F8F8FF; }

body.color_v5 .couleur3_hover:hover {
  color: #F8F8FF; }

body.color_v5 .couleur3_bg {
  background-color: #F8F8FF; }

body.color_v5 .button_color3 {
  color: #F8F8FF;
  border: 1px solid #F8F8FF; }
  body.color_v5 .button_color3:focus, body.color_v5 .button_color3:hover {
    color: #fff;
    border: 1px solid #F8F8FF;
    background-color: #F8F8FF; }

body.color_v5 .couleur3_hover:hover .rond_inside {
  color: #F8F8FF !important; }

body.color_v5 .couleur3_bg_hover:hover .rond_inside {
  background-color: #F8F8FF !important; }

body.color_v5 .border_color3 {
  border-color: #F8F8FF; }

body.color_v5 .border_top_color3 {
  border-top-color: #F8F8FF; }

body.color_v5 .button_color3_back {
  color: #fff;
  border: 1px solid #F8F8FF;
  background-color: #F8F8FF; }
  body.color_v5 .button_color3_back:hover {
    border: 1px solid #F8F8FF;
    color: #F8F8FF;
    background-color: #fff; }

body.color_v6 .couleur1 {
  color: #D56846; }

body.color_v6 .couleur1_hover:hover {
  color: #D56846; }

body.color_v6 .couleur1_bg {
  background-color: #D56846; }

body.color_v6 .button_color1 {
  color: #D56846;
  border: 1px solid #D56846; }
  body.color_v6 .button_color1:focus, body.color_v6 .button_color1:hover {
    color: #fff;
    border: 1px solid #D56846;
    background-color: #D56846; }

body.color_v6 .couleur1_hover:hover .rond_inside {
  color: #D56846 !important; }

body.color_v6 .couleur1_bg_hover:hover .rond_inside {
  background-color: #D56846 !important; }

body.color_v6 .border_color1 {
  border-color: #D56846; }

body.color_v6 .border_top_color1 {
  border-top-color: #D56846; }

body.color_v6 .button_color1_back {
  color: #fff;
  border: 1px solid #D56846;
  background-color: #D56846; }
  body.color_v6 .button_color1_back:hover {
    border: 1px solid #D56846;
    color: #D56846;
    background-color: #fff; }

body.color_v6 .couleur2 {
  color: #FBF3E9; }

body.color_v6 .couleur2_hover:hover {
  color: #FBF3E9; }

body.color_v6 .couleur2_bg {
  background-color: #FBF3E9; }

body.color_v6 .button_color2 {
  color: #FBF3E9;
  border: 1px solid #FBF3E9; }
  body.color_v6 .button_color2:focus, body.color_v6 .button_color2:hover {
    color: #fff;
    border: 1px solid #FBF3E9;
    background-color: #FBF3E9; }

body.color_v6 .couleur2_hover:hover .rond_inside {
  color: #FBF3E9 !important; }

body.color_v6 .couleur2_bg_hover:hover .rond_inside {
  background-color: #FBF3E9 !important; }

body.color_v6 .border_color2 {
  border-color: #FBF3E9; }

body.color_v6 .border_top_color2 {
  border-top-color: #FBF3E9; }

body.color_v6 .button_color2_back {
  color: #fff;
  border: 1px solid #FBF3E9;
  background-color: #FBF3E9; }
  body.color_v6 .button_color2_back:hover {
    border: 1px solid #FBF3E9;
    color: #FBF3E9;
    background-color: #fff; }

body.color_v6 .couleur3 {
  color: #F7E8D3; }

body.color_v6 .couleur3_hover:hover {
  color: #F7E8D3; }

body.color_v6 .couleur3_bg {
  background-color: #F7E8D3; }

body.color_v6 .button_color3 {
  color: #F7E8D3;
  border: 1px solid #F7E8D3; }
  body.color_v6 .button_color3:focus, body.color_v6 .button_color3:hover {
    color: #fff;
    border: 1px solid #F7E8D3;
    background-color: #F7E8D3; }

body.color_v6 .couleur3_hover:hover .rond_inside {
  color: #F7E8D3 !important; }

body.color_v6 .couleur3_bg_hover:hover .rond_inside {
  background-color: #F7E8D3 !important; }

body.color_v6 .border_color3 {
  border-color: #F7E8D3; }

body.color_v6 .border_top_color3 {
  border-top-color: #F7E8D3; }

body.color_v6 .button_color3_back {
  color: #fff;
  border: 1px solid #F7E8D3;
  background-color: #F7E8D3; }
  body.color_v6 .button_color3_back:hover {
    border: 1px solid #F7E8D3;
    color: #F7E8D3;
    background-color: #fff; }

body.color_v7 .couleur1 {
  color: #243526; }

body.color_v7 .couleur1_hover:hover {
  color: #243526; }

body.color_v7 .couleur1_bg {
  background-color: #243526; }

body.color_v7 .button_color1 {
  color: #243526;
  border: 1px solid #243526; }
  body.color_v7 .button_color1:focus, body.color_v7 .button_color1:hover {
    color: #fff;
    border: 1px solid #243526;
    background-color: #243526; }

body.color_v7 .couleur1_hover:hover .rond_inside {
  color: #243526 !important; }

body.color_v7 .couleur1_bg_hover:hover .rond_inside {
  background-color: #243526 !important; }

body.color_v7 .border_color1 {
  border-color: #243526; }

body.color_v7 .border_top_color1 {
  border-top-color: #243526; }

body.color_v7 .button_color1_back {
  color: #fff;
  border: 1px solid #243526;
  background-color: #243526; }
  body.color_v7 .button_color1_back:hover {
    border: 1px solid #243526;
    color: #243526;
    background-color: #fff; }

body.color_v7 .couleur2 {
  color: #EEF2E4; }

body.color_v7 .couleur2_hover:hover {
  color: #EEF2E4; }

body.color_v7 .couleur2_bg {
  background-color: #EEF2E4; }

body.color_v7 .button_color2 {
  color: #EEF2E4;
  border: 1px solid #EEF2E4; }
  body.color_v7 .button_color2:focus, body.color_v7 .button_color2:hover {
    color: #fff;
    border: 1px solid #EEF2E4;
    background-color: #EEF2E4; }

body.color_v7 .couleur2_hover:hover .rond_inside {
  color: #EEF2E4 !important; }

body.color_v7 .couleur2_bg_hover:hover .rond_inside {
  background-color: #EEF2E4 !important; }

body.color_v7 .border_color2 {
  border-color: #EEF2E4; }

body.color_v7 .border_top_color2 {
  border-top-color: #EEF2E4; }

body.color_v7 .button_color2_back {
  color: #fff;
  border: 1px solid #EEF2E4;
  background-color: #EEF2E4; }
  body.color_v7 .button_color2_back:hover {
    border: 1px solid #EEF2E4;
    color: #EEF2E4;
    background-color: #fff; }

body.color_v7 .couleur3 {
  color: #CDD8B3; }

body.color_v7 .couleur3_hover:hover {
  color: #CDD8B3; }

body.color_v7 .couleur3_bg {
  background-color: #CDD8B3; }

body.color_v7 .button_color3 {
  color: #CDD8B3;
  border: 1px solid #CDD8B3; }
  body.color_v7 .button_color3:focus, body.color_v7 .button_color3:hover {
    color: #fff;
    border: 1px solid #CDD8B3;
    background-color: #CDD8B3; }

body.color_v7 .couleur3_hover:hover .rond_inside {
  color: #CDD8B3 !important; }

body.color_v7 .couleur3_bg_hover:hover .rond_inside {
  background-color: #CDD8B3 !important; }

body.color_v7 .border_color3 {
  border-color: #CDD8B3; }

body.color_v7 .border_top_color3 {
  border-top-color: #CDD8B3; }

body.color_v7 .button_color3_back {
  color: #fff;
  border: 1px solid #CDD8B3;
  background-color: #CDD8B3; }
  body.color_v7 .button_color3_back:hover {
    border: 1px solid #CDD8B3;
    color: #CDD8B3;
    background-color: #fff; }

body.color_v8 .couleur1 {
  color: #A48EF8; }

body.color_v8 .couleur1_hover:hover {
  color: #A48EF8; }

body.color_v8 .couleur1_bg {
  background-color: #A48EF8; }

body.color_v8 .button_color1 {
  color: #A48EF8;
  border: 1px solid #A48EF8; }
  body.color_v8 .button_color1:focus, body.color_v8 .button_color1:hover {
    color: #fff;
    border: 1px solid #A48EF8;
    background-color: #A48EF8; }

body.color_v8 .couleur1_hover:hover .rond_inside {
  color: #A48EF8 !important; }

body.color_v8 .couleur1_bg_hover:hover .rond_inside {
  background-color: #A48EF8 !important; }

body.color_v8 .border_color1 {
  border-color: #A48EF8; }

body.color_v8 .border_top_color1 {
  border-top-color: #A48EF8; }

body.color_v8 .button_color1_back {
  color: #fff;
  border: 1px solid #A48EF8;
  background-color: #A48EF8; }
  body.color_v8 .button_color1_back:hover {
    border: 1px solid #A48EF8;
    color: #A48EF8;
    background-color: #fff; }

body.color_v8 .couleur2 {
  color: #EEE9FC; }

body.color_v8 .couleur2_hover:hover {
  color: #EEE9FC; }

body.color_v8 .couleur2_bg {
  background-color: #EEE9FC; }

body.color_v8 .button_color2 {
  color: #EEE9FC;
  border: 1px solid #EEE9FC; }
  body.color_v8 .button_color2:focus, body.color_v8 .button_color2:hover {
    color: #fff;
    border: 1px solid #EEE9FC;
    background-color: #EEE9FC; }

body.color_v8 .couleur2_hover:hover .rond_inside {
  color: #EEE9FC !important; }

body.color_v8 .couleur2_bg_hover:hover .rond_inside {
  background-color: #EEE9FC !important; }

body.color_v8 .border_color2 {
  border-color: #EEE9FC; }

body.color_v8 .border_top_color2 {
  border-top-color: #EEE9FC; }

body.color_v8 .button_color2_back {
  color: #fff;
  border: 1px solid #EEE9FC;
  background-color: #EEE9FC; }
  body.color_v8 .button_color2_back:hover {
    border: 1px solid #EEE9FC;
    color: #EEE9FC;
    background-color: #fff; }

body.color_v8 .couleur3 {
  color: #F1F0F5; }

body.color_v8 .couleur3_hover:hover {
  color: #F1F0F5; }

body.color_v8 .couleur3_bg {
  background-color: #F1F0F5; }

body.color_v8 .button_color3 {
  color: #F1F0F5;
  border: 1px solid #F1F0F5; }
  body.color_v8 .button_color3:focus, body.color_v8 .button_color3:hover {
    color: #fff;
    border: 1px solid #F1F0F5;
    background-color: #F1F0F5; }

body.color_v8 .couleur3_hover:hover .rond_inside {
  color: #F1F0F5 !important; }

body.color_v8 .couleur3_bg_hover:hover .rond_inside {
  background-color: #F1F0F5 !important; }

body.color_v8 .border_color3 {
  border-color: #F1F0F5; }

body.color_v8 .border_top_color3 {
  border-top-color: #F1F0F5; }

body.color_v8 .button_color3_back {
  color: #fff;
  border: 1px solid #F1F0F5;
  background-color: #F1F0F5; }
  body.color_v8 .button_color3_back:hover {
    border: 1px solid #F1F0F5;
    color: #F1F0F5;
    background-color: #fff; }

body.color_v9 .couleur1 {
  color: #DF5143; }

body.color_v9 .couleur1_hover:hover {
  color: #DF5143; }

body.color_v9 .couleur1_bg {
  background-color: #DF5143; }

body.color_v9 .button_color1 {
  color: #DF5143;
  border: 1px solid #DF5143; }
  body.color_v9 .button_color1:focus, body.color_v9 .button_color1:hover {
    color: #fff;
    border: 1px solid #DF5143;
    background-color: #DF5143; }

body.color_v9 .couleur1_hover:hover .rond_inside {
  color: #DF5143 !important; }

body.color_v9 .couleur1_bg_hover:hover .rond_inside {
  background-color: #DF5143 !important; }

body.color_v9 .border_color1 {
  border-color: #DF5143; }

body.color_v9 .border_top_color1 {
  border-top-color: #DF5143; }

body.color_v9 .button_color1_back {
  color: #fff;
  border: 1px solid #DF5143;
  background-color: #DF5143; }
  body.color_v9 .button_color1_back:hover {
    border: 1px solid #DF5143;
    color: #DF5143;
    background-color: #fff; }

body.color_v9 .couleur2 {
  color: #F9DEAD; }

body.color_v9 .couleur2_hover:hover {
  color: #F9DEAD; }

body.color_v9 .couleur2_bg {
  background-color: #F9DEAD; }

body.color_v9 .button_color2 {
  color: #F9DEAD;
  border: 1px solid #F9DEAD; }
  body.color_v9 .button_color2:focus, body.color_v9 .button_color2:hover {
    color: #fff;
    border: 1px solid #F9DEAD;
    background-color: #F9DEAD; }

body.color_v9 .couleur2_hover:hover .rond_inside {
  color: #F9DEAD !important; }

body.color_v9 .couleur2_bg_hover:hover .rond_inside {
  background-color: #F9DEAD !important; }

body.color_v9 .border_color2 {
  border-color: #F9DEAD; }

body.color_v9 .border_top_color2 {
  border-top-color: #F9DEAD; }

body.color_v9 .button_color2_back {
  color: #fff;
  border: 1px solid #F9DEAD;
  background-color: #F9DEAD; }
  body.color_v9 .button_color2_back:hover {
    border: 1px solid #F9DEAD;
    color: #F9DEAD;
    background-color: #fff; }

body.color_v9 .couleur3 {
  color: #FCEDCE; }

body.color_v9 .couleur3_hover:hover {
  color: #FCEDCE; }

body.color_v9 .couleur3_bg {
  background-color: #FCEDCE; }

body.color_v9 .button_color3 {
  color: #FCEDCE;
  border: 1px solid #FCEDCE; }
  body.color_v9 .button_color3:focus, body.color_v9 .button_color3:hover {
    color: #fff;
    border: 1px solid #FCEDCE;
    background-color: #FCEDCE; }

body.color_v9 .couleur3_hover:hover .rond_inside {
  color: #FCEDCE !important; }

body.color_v9 .couleur3_bg_hover:hover .rond_inside {
  background-color: #FCEDCE !important; }

body.color_v9 .border_color3 {
  border-color: #FCEDCE; }

body.color_v9 .border_top_color3 {
  border-top-color: #FCEDCE; }

body.color_v9 .button_color3_back {
  color: #fff;
  border: 1px solid #FCEDCE;
  background-color: #FCEDCE; }
  body.color_v9 .button_color3_back:hover {
    border: 1px solid #FCEDCE;
    color: #FCEDCE;
    background-color: #fff; }

body.color_v10 .couleur1 {
  color: #FFFFFF; }

body.color_v10 .couleur1_hover:hover {
  color: #FFFFFF; }

body.color_v10 .couleur1_bg {
  background-color: #FFFFFF; }

body.color_v10 .button_color1 {
  color: #FFFFFF;
  border: 1px solid #FFFFFF; }
  body.color_v10 .button_color1:focus, body.color_v10 .button_color1:hover {
    color: #fff;
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF; }

body.color_v10 .couleur1_hover:hover .rond_inside {
  color: #FFFFFF !important; }

body.color_v10 .couleur1_bg_hover:hover .rond_inside {
  background-color: #FFFFFF !important; }

body.color_v10 .border_color1 {
  border-color: #FFFFFF; }

body.color_v10 .border_top_color1 {
  border-top-color: #FFFFFF; }

body.color_v10 .button_color1_back {
  color: #fff;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF; }
  body.color_v10 .button_color1_back:hover {
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    background-color: #fff; }

body.color_v10 .couleur2 {
  color: #000000; }

body.color_v10 .couleur2_hover:hover {
  color: #000000; }

body.color_v10 .couleur2_bg {
  background-color: #000000; }

body.color_v10 .button_color2 {
  color: #000000;
  border: 1px solid #000000; }
  body.color_v10 .button_color2:focus, body.color_v10 .button_color2:hover {
    color: #fff;
    border: 1px solid #000000;
    background-color: #000000; }

body.color_v10 .couleur2_hover:hover .rond_inside {
  color: #000000 !important; }

body.color_v10 .couleur2_bg_hover:hover .rond_inside {
  background-color: #000000 !important; }

body.color_v10 .border_color2 {
  border-color: #000000; }

body.color_v10 .border_top_color2 {
  border-top-color: #000000; }

body.color_v10 .button_color2_back {
  color: #fff;
  border: 1px solid #000000;
  background-color: #000000; }
  body.color_v10 .button_color2_back:hover {
    border: 1px solid #000000;
    color: #000000;
    background-color: #fff; }

body.color_v10 .couleur3 {
  color: #000000; }

body.color_v10 .couleur3_hover:hover {
  color: #000000; }

body.color_v10 .couleur3_bg {
  background-color: #000000; }

body.color_v10 .button_color3 {
  color: #000000;
  border: 1px solid #000000; }
  body.color_v10 .button_color3:focus, body.color_v10 .button_color3:hover {
    color: #fff;
    border: 1px solid #000000;
    background-color: #000000; }

body.color_v10 .couleur3_hover:hover .rond_inside {
  color: #000000 !important; }

body.color_v10 .couleur3_bg_hover:hover .rond_inside {
  background-color: #000000 !important; }

body.color_v10 .border_color3 {
  border-color: #000000; }

body.color_v10 .border_top_color3 {
  border-top-color: #000000; }

body.color_v10 .button_color3_back {
  color: #fff;
  border: 1px solid #000000;
  background-color: #000000; }
  body.color_v10 .button_color3_back:hover {
    border: 1px solid #000000;
    color: #000000;
    background-color: #fff; }

/* Couleurs inversées pour la déclinaison de couleur 10 */
html body.color_v10 {
  color: #fff;
  background: #000;
  /* Theme 6 */
  /* Thème 7 */ }
  html body.color_v10 a {
    color: #fff; }
    html body.color_v10 a:hover {
      opacity: .8; }
  html body.color_v10 h1,
  html body.color_v10 .h1,
  html body.color_v10 h2,
  html body.color_v10 .h2,
  html body.color_v10 h3,
  html body.color_v10 .h3,
  html body.color_v10 h4,
  html body.color_v10 .h4,
  html body.color_v10 #insurance .h2,
  html body.color_v10 #insurance h2,
  html body.color_v10 #paiement .h2,
  html body.color_v10 #paiement h2,
  html body.color_v10 #master_brand .h2,
  html body.color_v10 #master_brand h2,
  html body.color_v10 .formulaire .stitle {
    color: #fff; }
  html body.color_v10 .button_color1_back {
    border-color: #000;
    background-color: #000; }
  html body.color_v10 .couleur2_bg {
    background-color: #fff; }
    html body.color_v10 .couleur2_bg h2,
    html body.color_v10 .couleur2_bg .h2 {
      color: #000; }
  html body.color_v10 #header.sticky #sticky_header {
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: none;
    border-bottom: 0.1rem solid #fff; }
  html body.color_v10 .sticky-favori a.btn_favo:hover {
    color: #000;
    background-color: #fff;
    opacity: 1; }
  html body.color_v10 .presentation .left-block .title {
    color: #000; }
  html body.color_v10 .presentation .left-block .left-block-content {
    color: #000; }
  html body.color_v10 .post-card {
    background-color: #444; }
  html body.color_v10 .btn-outline-white {
    color: #000;
    border-color: #000; }
  html body.color_v10 .btn-outline {
    border-color: #fff;
    background-color: #000; }
    html body.color_v10 .btn-outline:hover {
      color: #000;
      border-color: #000;
      background-color: #fff;
      opacity: 1; }
  html body.color_v10 .btn-demi-color2::after {
    background-color: #444; }
  html body.color_v10 .btn-demi-color2:hover {
    color: #fff;
    border-color: #fff;
    background-color: #000; }
    html body.color_v10 .btn-demi-color2:hover::after {
      background-color: #000; }
  html body.color_v10 .btn-hover-color1:hover {
    color: #000; }
  html body.color_v10 .title-lined::before {
    background-color: #fff; }
  html body.color_v10 .title-lined span {
    color: #fff;
    background-color: #000; }
  html body.color_v10 .title-subborder {
    color: #fff;
    border-bottom-color: #fff; }
  html body.color_v10 .card-offre .img-offre .date-offre {
    color: #000; }
  html body.color_v10 .card-offre .texte-offre,
  html body.color_v10 .card-offre .see-more-offre {
    color: rgba(255, 255, 255, 0.7); }
  html body.color_v10 #bloc_instagram {
    color: #fff;
    background-color: #000; }
    html body.color_v10 #bloc_instagram h2,
    html body.color_v10 #bloc_instagram .title {
      color: #fff; }
  html body.color_v10 .liste-avantages .avantage {
    color: #000; }
    html body.color_v10 .liste-avantages .avantage .icon {
      color: #000; }
  html body.color_v10 .card-team img {
    border-color: #fff; }
  html body.color_v10 #miroir_virtuel h2 {
    color: #fff; }
  html body.color_v10 #miroir_virtuel .white_bg {
    padding-bottom: 3rem; }
  html body.color_v10 #footer {
    border-top: .1rem solid #fff;
    background-color: #000; }
  html body.color_v10 .news_block .news_content {
    margin: 0;
    padding: 1rem 1.2rem; }
  html body.color_v10 .news_block .news_date {
    color: #000; }
  html body.color_v10 #news .news_content {
    background-color: #000; }
  html body.color_v10 .bloc_actu .liste-news .card-offre .content-offre .texte-offre {
    color: #fff; }
  html body.color_v10 .mp-menu {
    color: #fff;
    background-color: #000; }
    html body.color_v10 .mp-menu > .mp-level > ul .couleur3 {
      color: #fff; }
      html body.color_v10 .mp-menu > .mp-level > ul .couleur3:hover > span {
        color: #aaa; }
  html body.color_v10 .mp-level {
    background-color: #222; }
    html body.color_v10 .mp-level > ul > li > a {
      box-shadow: inset 0 1px rgba(255, 255, 255, 0.3); }
  html body.color_v10 .picto-menu {
    background-color: #fff; }
    html body.color_v10 .picto-menu::before, html body.color_v10 .picto-menu::after {
      background-color: #fff; }
  html body.color_v10.menuhidden .picto-menu {
    background-color: transparent; }
  html body.color_v10 .fancybox-slide > * {
    background-color: #000; }
  html body.color_v10 #cookies-options .alert-close a.button_color1_back {
    border-color: #fff;
    background-color: #fff;
    color: #000; }
    html body.color_v10 #cookies-options .alert-close a.button_color1_back:hover {
      color: #fff;
      background-color: #000;
      opacity: 1; }
  html body.color_v10 #cookies-options .alert-close a,
  html body.color_v10 #cookies-options .cookies-waiting-content a {
    border: .1rem solid #fff; }
    html body.color_v10 #cookies-options .alert-close a:hover,
    html body.color_v10 #cookies-options .cookies-waiting-content a:hover {
      color: #000;
      background-color: #fff;
      opacity: 1; }
  html body.color_v10 #cookies-options table tbody tr:nth-child(2n+1) {
    color: #fff; }
  html body.color_v10 #cookies-content-barre {
    border-top: .1rem solid #fff;
    background-color: #000; }
    html body.color_v10 #cookies-content-barre a,
    html body.color_v10 #cookies-content-barre .button_color1_back {
      border: .1rem solid #fff; }
      html body.color_v10 #cookies-content-barre a:hover,
      html body.color_v10 #cookies-content-barre .button_color1_back:hover {
        color: #000;
        background-color: #fff;
        opacity: 1; }
  html body.color_v10 .presentation {
    color: #000; }
    html body.color_v10 .presentation h1 {
      color: #000; }
    html body.color_v10 .presentation::after {
      background-image: url(../img/theme/theme6/wave-black.svg); }
    html body.color_v10 .presentation .btn-outline-black {
      color: #000; }
      html body.color_v10 .presentation .btn-outline-black:hover {
        color: #fff;
        background-color: #000; }
  html body.color_v10 .card-actu .desc .desc-content,
  html body.color_v10 .card-actu .desc .h4 {
    color: #000; }
  html body.color_v10 .liste-offres a {
    color: #000; }
  html body.color_v10 .liste-offres .texte-offre {
    color: #000; }
  html body.color_v10 .bloc-avantages h2 {
    color: #000; }
  html body.color_v10 .bloc-avantages .avantage {
    color: #fff;
    background-color: #000; }
    html body.color_v10 .bloc-avantages .avantage .icon {
      color: #fff; }
  html body.color_v10 .bandeau_marques h2 {
    color: #000; }
  html body.color_v10 .footer-nl .h4 {
    color: #fff; }
  html body.color_v10 .footer-nl .message-rgpd {
    color: #fff; }
  html body.color_v10 .main-footer .h3 {
    color: #fff; }
  html body.color_v10.theme-7 .infos-mag .container .infos-mag-block-horaires li .current_day {
    color: #888; }
  html body.color_v10.theme-7 .card-actu .desc .desc-content,
  html body.color_v10.theme-7 .card-actu .desc .h4 {
    color: #fff; }
  html body.color_v10.theme-7 .home-avantages .h2 {
    color: #000; }
  html body.color_v10.theme-7 .bandeau_marques h2 {
    color: #fff; }

html {
  font-size: 100%;
  overflow-x: hidden; }

body {
  font-family: 'Inter',sans-serif;
  font-size: 16px;
  color: #707070;
  background: #fff; }

hr {
  color: #d4d7d9;
  border: 1px inset #d4d7d9; }

.fixed {
  position: fixed; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static;
  overflow: hidden; }

.hyphenate {
  text-align: left;
  -webkit-hyphens: auto;
  hyphens: auto;
  -ms-hyphens: auto; }

.inline {
  display: inline; }

.inlineblock {
  display: inline-block; }

.block {
  display: block; }

.floatleft {
  float: left; }

.floatright {
  float: right; }

.floatnone {
  float: none; }

.textleft {
  text-align: left; }

.textright {
  text-align: right; }

.textcenter {
  text-align: center; }

.textjustify {
  text-align: justify; }

.width80 {
  width: 80%; }

.width60 {
  width: 60%; }

.width50 {
  width: 50%; }

.width47 {
  width: 47%; }

.width45 {
  width: 45%; }

.width35 {
  width: 35%; }

.width33 {
  width: 33.33333333%; }

.width25 {
  width: 25%; }

.margin0 {
  margin: 0; }

.marginauto {
  margin: auto; }

.lineh15 {
  line-height: 1.5; }

.lineh25 {
  line-height: 2.5; }

.height465 {
  height: 465px; }

.bold {
  font-weight: 700; }

.normal {
  font-weight: 400; }

.marginright {
  margin-right: 1em; }

.marginleft {
  margin-left: 1.5em; }

.margin_left_20 {
  margin-left: 20px; }

.marginbottom {
  margin-bottom: 2em !important; }

.marginbottomlight {
  margin-bottom: 1em; }

.margintop {
  margin-top: 2em; }

.margintoplight {
  margin-top: 2em; }

.margintopultralight {
  margin-top: .5em; }

.margintop-2 {
  margin-top: -2em; }

.container900 {
  max-width: 900px;
  margin: 0 auto; }

.padding10 {
  padding: 10px; }

.paddingtopbot20 {
  padding: 20px 0; }

.padding1020 {
  padding: 10px 20px; }

.padding25 {
  padding: 25px; }

.padding30 {
  padding: 30px; }

.padding40 {
  padding: 40px; }

.padding60 {
  padding: 60px; }

.padding6040 {
  padding: 60px 40px; }

.padding7040 {
  padding: 70px 40px; }

.padding4070 {
  padding: 40px 3%; }

.padding4015 {
  padding: 40px 15px; }

.vmiddle {
  vertical-align: middle; }

.vtop {
  vertical-align: top; }

.vbottom {
  vertical-align: bottom; }

.fontsize80 {
  font-size: 80%; }

.fontsize85 {
  font-size: 85%; }

.fontsize90 {
  font-size: 90%; }

.fontsize110 {
  font-size: 110%; }

.fontsize120 {
  font-size: 120%; }

.margin0auto {
  display: block;
  margin: 0 auto; }

.lowercase {
  text-transform: lowercase; }

.uppercase {
  text-transform: uppercase; }

.mobile {
  display: none; }

.desktop {
  display: block; }

img {
  max-width: 100%; }

.aheight,
.centered-height {
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle; }

.sep {
  display: block;
  width: 100%;
  height: 1px; }

.hidden,
.masque,
figcaption {
  display: none; }

a {
  transition: all .3s ease;
  color: #000; }

.container {
  position: relative;
  width: 1760px;
  margin-top: auto;
  margin-right: auto;
  margin-left: auto;
  max-width: calc(100% - 4rem); }

section {
  margin-bottom: 60px; }

.cover_bg {
  background-position: center center;
  background-size: cover; }

.contain_bg {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain; }

.flexbox {
  display: flex;
  flex-direction: column; }

.ratio_2_3 {
  width: 100%;
  height: 0;
  padding-top: 66.66%; }

.ratio_3_4 {
  width: 100%;
  height: 0;
  padding-top: 75%; }

.height_230 {
  height: 240px; }

.height_260 {
  height: 260px; }

.height_148 {
  height: 148px; }

.height_200 {
  height: 200px; }

.height_295 {
  height: 295px; }

.height_350 {
  height: 350px; }

.abs_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

a, .lien-off {
  transition: all .3s ease-in-out;
  text-decoration: none; }

.lien-off {
  cursor: pointer; }

.alert-close a.button_color1_back {
  padding: 10px 15px; }

.swip-checkbox [type="checkbox"]:checked + label::before {
  background-color: #0a0; }

.swip-checkbox [type="checkbox"]:checked + label .ui::after {
  color: #fff; }

.swip-checkbox [type="checkbox"]:checked + label::after {
  background-color: #fff; }

.swip-checkbox [type="checkbox"]:not(:checked) + label::after {
  background-color: #b12626; }

.swip-checkbox [type="checkbox"]:not(:checked) + label .ui::before {
  color: #b12626; }

#menu_launcher {
  display: none;
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  width: 40px;
  height: 40px;
  margin: auto;
  color: #fff;
  cursor: pointer; }

.no-csstransforms3d .mp-pusher,
.no-js .mp-pusher {
  padding-left: 300px; }

.no-csstransforms3d .mp-menu .mp-level,
.no-js .mp-menu .mp-level {
  display: none; }

.no-csstransforms3d .mp-menu > .mp-level,
.no-js .mp-menu > .mp-level {
  display: block; }

.mp-menu {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  transition: transform .5s,-webkit-transform .5s;
  transform: translateX(-100%);
  background: #fff; }

.mp-level > ul > li > a > span {
  color: #333; }

.mp-level > ul > li > a:hover > span {
  color: #706d70; }

.mp-level {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 50px);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: #f4f4f4; }

.mp-menu > .mp-level {
  top: 50px; }

.mp-menu > .mp-level.mp-level-open {
  height: calc(100% - 50px); }

.mp-level.mp-level-open {
  overflow: auto;
  height: calc(100vh - 50px); }

.mp-level.mp-level-open.mp-level-overlay {
  overflow: hidden;
  height: 200vh; }

.mp-level.mp-level-open.mp-level-overlay > ul > li:not(.m_current) {
  visibility: hidden;
  opacity: 0; }

.mp-level.mp-level-overlay {
  cursor: pointer; }

.mp-level.mp-level-overlay.mp-level:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: 0 0; }

.mp-level,
.mp-pusher {
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s; }

.mp-overlap .mp-level.mp-level-open {
  -webkit-transform: translateX(-40px);
  transform: translateX(-40px);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2); }

.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  box-shadow: none; }

.mp-cover .mp-level.mp-level-open {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.mp-menu .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 40px;
  display: block;
  min-height: 40px;
  margin: 0 20px;
  padding: .7em 0;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1); }

.mp-menu .title .text {
  margin-left: 20px;
  vertical-align: middle; }

.mp-menu .title .picto + .text {
  margin-left: 0; }

.mp-menu .close_bloc .title,
.mp-menu .mp-level .mp-level .mp-level .title {
  color: #fff; }

.mp-menu .mp-level .mp-level .mp-level .title a {
  line-height: 18px;
  display: inline-block;
  padding-bottom: 3px;
  vertical-align: middle; }

.mp-menu .title a:hover,
.mp-menu ul li .title a:hover {
  color: #56c1ff;
  background: 0 0; }

.mp-menu .title .picto,
.mp-menu .title a .picto {
  font-size: 40px;
  line-height: 40px;
  overflow: visible;
  width: auto;
  margin: 0 5px; }

.mp-menu .title .picto + .centered-height {
  width: calc(100% - 60px); }

.mp-menu.mp-overlap .title:before {
  font-size: 75%;
  line-height: 1.8;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 8px;
  transition: opacity .3s,-webkit-transform .1s .3s;
  transition: opacity .3s,transform .1s .3s;
  transition: opacity .3s,transform .1s .3s,-webkit-transform .1s .3s;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0; }

.mp-overlap .mp-level.mp-level-overlay > .title:before {
  transition: opacity .3s,-webkit-transform .3s;
  transition: transform .3s,opacity .3s;
  transition: transform .3s,opacity .3s,-webkit-transform .3s;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1; }

.mp-menu ul {
  overflow: hidden;
  list-style: none;
  text-align: left; }

.mp-menu > .mp-level > ul {
  position: relative;
  overflow: auto;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 0; }

.mp-menu ul li:before {
  line-height: 30px;
  position: absolute;
  z-index: -1;
  left: 10px;
  color: rgba(0, 0, 0, 0.8); }

.mp-menu ul li > a {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  padding: .7em 1em .7em 1em;
  transition: background .3s,box-shadow .3s;
  text-transform: uppercase;
  outline: 0; }

.mp-menu ul li > a .icon {
  font-size: 12px; }

.mp-level a.favoris-menu-mobile {
  color: #fff;
  background-color: #9fa8bd;
  text-align: center;
  padding: 0 0 1rem; }

.mp-level a.favoris-menu-mobile .text {
  color: #fff;
  transition: color .3s; }

.mp-level a.favoris-menu-mobile:hover .text {
  padding-left: 0; }

.mp-menu ul li > a .icon-favoris {
  font-size: 6rem; }

.mp-level a {
  color: rgba(0, 0, 0, 0.7); }

#mp-menu .mp-level ul {
  list-style: none; }

#mp-menu .mp-level-2 a {
  line-height: 20px;
  vertical-align: middle;
  text-transform: none; }

#mp-menu .mp-level-2 .centered-height a {
  line-height: 1;
  display: inline-block;
  float: none;
  vertical-align: middle; }

#mp-menu .mp-level-2 .c_container a,
#mp-menu .mp-level-2 .c_container span {
  color: rgba(255, 255, 255, 0.7); }

#mp-menu .mp-level-2 a.mp-back {
  text-transform: uppercase; }

#mp-menu .mp-level-2 ul {
  padding: 5px 0; }

#mp-menu .mp-level-2 li {
  padding: 0 5px; }

.mp-level a .text {
  padding-left: 0;
  transition: border-color .2s ease-in,padding .2s ease-in;
  vertical-align: middle; }

.mp-level a:hover .text {
  padding-left: 5px; }

.mp-level a .picto {
  font-size: 22px;
  overflow: visible;
  width: 24px;
  margin: 0 10px 0 0; }

.mp-level > ul > li > a {
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.2); }

.mp-menu.out ul li a:hover {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px transparent; }

.mp-level.mp-level-overlay > ul > li:first-child > a,
.mp-menu.out .mp-level.mp-level-overlay > ul > li > a {
  box-shadow: inset 0 -1px transparent; }

#mp-menu .mp-level .picto_menu {
  position: static;
  margin: 0 0 0 6px;
  transition: border-color .2s ease-in,margin .3s ease-in;
  vertical-align: middle;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.7); }

#mp-menu .mp-level a:hover .picto_menu {
  border-color: transparent transparent transparent #fff; }

.mp-back {
  font-size: 1.2em;
  font-weight: 700;
  position: relative;
  display: block;
  padding: 1em 1em 1em 3.4em;
  transition: color .2s,background .3s,padding .2s;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  outline: 0;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1); }

.mp-back:hover {
  padding: 1em 1em 1em 4em; }

.mp-menu.out .mp-level.mp-level-overlay > .mp-back {
  color: transparent;
  background: 0 0;
  box-shadow: none; }

#mp-menu .mp-back .picto_menu,
#mp-menu .mp-level-2 .mp-back .picto_menu {
  position: absolute;
  top: 50%;
  left: 18px;
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  vertical-align: middle;
  border-width: 3px 4px 3px 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent; }

#mp-menu .mp-back .picto_menu {
  border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent; }

#mp-menu .mp-level .mp-back:hover .picto_menu {
  border-color: transparent #fff transparent transparent; }

.mp-level li {
  margin-left: 0; }

.m_event:after {
  font-family: kfont;
  margin: 0 20px;
  content: '\e98e'; }

#fancybox-content > div,
.fancybox-inner {
  overflow: hidden !important; }

#fancybox-content > div img {
  width: 100%; }

.mp-pusher {
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s,-webkit-transform .5s; }

.fancy_visible .scroller,
.menu_visible .scroller {
  overflow: hidden;
  height: 100%; }

.menu_visible html {
  overflow: hidden; }

.mp-fancy {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 340px;
  height: 100%;
  -webkit-transform: translateX(-340px);
  transform: translateX(-340px); }

.scroller:before {
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  content: ''; }

.scroller.mp-pushed {
  cursor: pointer; }

.scroller.mp-pushed:before {
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  transition: background .5s;
  background: rgba(0, 0, 0, 0.5); }

.mp-pusher.fancy_visible {
  -webkit-transform: translateX(340px);
  transform: translateX(340px); }

.mp-pusher.menu_visible {
  -webkit-transform: translateX(300px);
  transform: translateX(300px); }

.mp-pusher::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  content: '';
  opacity: 0; }

.mp-pusher.fancy_visible {
  -webkit-transform: translateX(340px);
  transform: translateX(340px); }

#mp-fancy > .header {
  position: relative;
  border-bottom: 5px solid #5cb39b; }

#menu_launcher a {
  display: block; }

#menu_launcher .text {
  font-size: 1.1em;
  line-height: 1;
  padding-bottom: 5px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #191818; }

.picto-menu {
  position: relative;
  top: 5px;
  left: 6px;
  display: inline-block;
  width: 28px;
  height: 2px;
  transition: all .3s ease-in-out 0s;
  background: #9fa8bf; }

.picto-menu:after,
.picto-menu:before {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 28px;
  height: 2px;
  content: '';
  transition: all .3s ease-in-out 0s;
  -webkit-transform-origin: center center 0;
  transform-origin: center center 0;
  background: #9fa8bf none repeat scroll 0 0; }

.picto-menu:before {
  top: 8px; }

.picto-menu:after {
  top: -8px; }

.menuhidden .picto-menu:before {
  height: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.menuhidden .picto-menu:after {
  height: 2px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.menuhidden .picto-menu:after,
.menuhidden .picto-menu:before {
  top: 0;
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0; }

.menuhidden .picto-menu {
  background: transparent none repeat scroll 0 0; }

.m_icon {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 2px;
  margin: 10px 0;
  vertical-align: middle;
  background: #191818; }

.m_icon:before {
  position: absolute;
  top: -8px;
  left: 0;
  display: block;
  width: 24px;
  height: 2px;
  content: '';
  background: #191818; }

.m_icon:after {
  position: absolute;
  bottom: -8px;
  left: 0;
  display: block;
  width: 24px;
  height: 2px;
  content: '';
  background: #191818; }

#mh_close {
  padding-top: 5px;
  padding-right: 15px;
  text-align: right; }

#mh_close .icon {
  padding: 10px;
  cursor: pointer; }

#logo_mh {
  margin: 5px 0;
  text-align: center; }

@media (max-width: 991px) {
  #menu_launcher {
    display: block; } }

/* Utiliser uniquement sur les nouveaux sites Thèmes 5-6-7 */
#master_brand .list_marques .card-brand {
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  border: 3px solid #e2dddd;
  width: 100%;
  height: 320px;
  display: block;
  text-align: center;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  transition: box-shadow .3s; }
  #master_brand .list_marques .card-brand figure {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    margin: 0 auto; }
    #master_brand .list_marques .card-brand figure img {
      object-fit: contain;
      max-height: 100%;
      margin-bottom: 20px; }
  #master_brand .list_marques .card-brand .text {
    font-size: 15px;
    font-weight: 500;
    color: #a0a8bf; }
  #master_brand .list_marques .card-brand:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5); }

#master_brand {
  margin-bottom: 4rem; }
  #master_brand .h3 {
    font-size: 22px; }
  #master_brand .icon_logo {
    display: none; }

#news {
  margin-bottom: 40px; }
  #news .pager_c {
    margin-top: 20px; }
  #news .container {
    width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px; }

.body_body_news .header-page-classic {
  height: 150px; }

.filter_header {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 400; }
  .filter_header form {
    margin-left: 20px; }
  .filter_header .text_label {
    color: #68728b;
    line-height: 1;
    font-size: 16px; }
  .filter_header .custom-select {
    margin-left: 10px; }
    .filter_header .custom-select::after {
      font-family: kfont;
      content: "\EA50";
      display: block;
      position: absolute;
      right: 5px;
      top: 3px;
      font-size: 8px;
      color: #abadb4;
      pointer-events: none; }

.custom-select {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #c5c5c5;
  cursor: pointer; }
  .custom-select select {
    font-size: 14px;
    font-weight: 500;
    color: #abadb4;
    background-color: #fff;
    border: none;
    appearance: none;
    padding: 4px 25px 4px 5px;
    cursor: pointer;
    min-width: 200px; }
    .custom-select select option {
      font-size: 12px; }
  .custom-select::after {
    font-family: kfont;
    content: "\EA02";
    display: block;
    position: absolute;
    right: 5px;
    top: 7px;
    font-size: 13px;
    color: #abadb4;
    pointer-events: none; }
  .custom-select:focus {
    color: red; }

/* Style générique */
.news_block .news_img {
  position: relative;
  display: block;
  transition: filter .3s; }
  .news_block .news_img img {
    aspect-ratio: 303 / 131;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover; }

.news_block .news_content {
  margin: 10px 12px; }

.news_block .news_date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #D22350;
  margin-bottom: 15px;
  padding: 5px 6px; }

.news_block .news_title {
  display: block;
  height: 44px;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  overflow: hidden;
  text-align: left; }

.news_block .news_desc {
  line-height: 1.5;
  margin-top: 5px;
  margin-bottom: 15px;
  height: 96px;
  overflow: hidden; }

.news_block .news-see-details {
  text-align: center;
  font-weight: 500;
  border-top: 2px solid transparent;
  padding-top: 8px; }
  .news_block .news-see-details a, .news_block .news-see-details .lien-off {
    display: inline-block; }
    .news_block .news-see-details a:hover, .news_block .news-see-details .lien-off:hover {
      color: #9FA8BD; }
    .news_block .news-see-details a .not-expanded, .news_block .news-see-details .lien-off .not-expanded {
      display: block; }
    .news_block .news-see-details a .expanded, .news_block .news-see-details .lien-off .expanded {
      display: none; }
      .news_block .news-see-details a .expanded .icon, .news_block .news-see-details .lien-off .expanded .icon {
        font-size: 25px;
        line-height: 0; }
    .news_block .news-see-details a.selected-card .not-expanded, .news_block .news-see-details .lien-off.selected-card .not-expanded {
      display: none; }
    .news_block .news-see-details a.selected-card .expanded, .news_block .news-see-details .lien-off.selected-card .expanded {
      display: block; }

.news_block .new_type_fournisseur .news_date {
  background-color: #417f35; }

.news_block .new_type_fournisseur .news_title {
  color: #417f35; }
  .news_block .new_type_fournisseur .news_title a, .news_block .new_type_fournisseur .news_title .lien-off {
    color: #417f35; }

.news_block .new_type_fournisseur .news-see-details {
  border-top-color: #417f35; }
  .news_block .new_type_fournisseur .news-see-details a, .news_block .new_type_fournisseur .news-see-details .lien-off {
    color: #417f35; }

.news_main .news-see-details {
  display: none; }

.news_main .news_img {
  position: relative; }
  .news_main .news_img img {
    aspect-ratio: 300/145; }
  .news_main .news_img .button_pink {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    font-weight: 400;
    font-size: 18px; }
    .news_main .news_img .button_pink:hover {
      color: #fff;
      background-color: #D22350; }
  .news_main .news_img .overlay-news {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity .3s; }
  .news_main .news_img:hover {
    filter: none; }
    .news_main .news_img:hover .overlay-news {
      opacity: 1;
      visibility: visible; }

.news_main .new_type_fournisseur .button_pink {
  background-color: #417f35;
  border-color: #417f35; }
  .news_main .new_type_fournisseur .button_pink:hover {
    background-color: #417f35; }

/* Style spé page news */
#offers_news #contenu .container {
  width: 100%; }

.bloc_actu .liste-news {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 2rem; }
  .bloc_actu .liste-news .card-offre .img-offre {
    margin-bottom: 0; }
    .bloc_actu .liste-news .card-offre .img-offre img {
      aspect-ratio: 303/131; }
    .bloc_actu .liste-news .card-offre .img-offre .date-offre {
      top: 0;
      right: 0;
      font-size: 14px;
      padding: 5px 6px; }
  .bloc_actu .liste-news .card-offre .content-offre {
    margin: 10px 12px; }
    .bloc_actu .liste-news .card-offre .content-offre .titre-offre {
      text-transform: uppercase;
      font-size: 18px;
      height: 44px;
      margin-bottom: 1em; }
    .bloc_actu .liste-news .card-offre .content-offre .texte-offre {
      color: #000;
      line-height: 1.5;
      margin-top: 5px;
      margin-bottom: 15px;
      height: 96px;
      overflow: hidden; }
    .bloc_actu .liste-news .card-offre .content-offre .btn-outline {
      text-align: center;
      font-weight: 500;
      border: none;
      border-top-width: 2px;
      border-top-style: solid;
      padding-top: 8px; }
      .bloc_actu .liste-news .card-offre .content-offre .btn-outline:hover {
        color: #9fa8bd;
        background-color: transparent; }
  .bloc_actu .liste-news .news_card {
    width: calc(25% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px; }
    .bloc_actu .liste-news .news_card .news_img {
      display: block;
      overflow: hidden; }

.autres-actus {
  margin-top: 25px;
  margin-bottom: 30px; }

.bloc_actu .news_card.new_type_fournisseur .news_date {
  background-color: #417F35; }

.bloc_actu .news_card.new_type_fournisseur .news_title {
  color: #417F35; }
  .bloc_actu .news_card.new_type_fournisseur .news_title a, .bloc_actu .news_card.new_type_fournisseur .news_title .lien-off {
    color: #417f35; }

.bloc_actu .news_card.new_type_fournisseur .news-see-details {
  border-top-color: #417F35; }
  .bloc_actu .news_card.new_type_fournisseur .news-see-details a, .bloc_actu .news_card.new_type_fournisseur .news-see-details .lien-off {
    color: #417F35; }

.bloc_actu .news_card.new_type_fournisseur .icon-close {
  color: #417F35; }

.detail_type_fournisseur .news_date {
  background-color: #417F35; }

.detail_type_fournisseur .news_title {
  color: #417F35; }
  .detail_type_fournisseur .news_title a, .detail_type_fournisseur .news_title .lien-off {
    color: #417f35; }

.detail_type_fournisseur .new-right-col .h1,
.detail_type_fournisseur .new-left-col .detail_date {
  color: #417F35; }

.detail_type_fournisseur .new-left-col .tags-liste .tag-new {
  color: #417F35;
  border-color: #417F35; }

/* Détail actualités */
.bloc_actu .liste-news .detail-new {
  top: 385px; }
  .bloc_actu .liste-news .detail-new .voir-plus {
    top: 650px; }
  .bloc_actu .liste-news .detail-new .detail-new-content {
    display: flex;
    border-top: 2px solid #ddd;
    padding-top: 60px; }

.new-detail {
  display: flex;
  margin-bottom: 60px; }
  .new-detail .new-left-col {
    width: 320px;
    margin-right: 40px; }
    .new-detail .new-left-col .detail_date {
      font-size: 17px;
      font-weight: 500;
      margin-top: 25px; }
    .new-detail .new-left-col .partage-new {
      display: flex;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 700;
      color: #000;
      margin-top: 10px; }
      .new-detail .new-left-col .partage-new .icon {
        font-family: kfont;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        background-color: #D9D9D9;
        border-radius: 50%;
        margin-left: 6px; }
      .new-detail .new-left-col .partage-new a:hover .icon,
      .new-detail .new-left-col .partage-new .lien-off:hover .icon {
        color: #D22350; }
    .new-detail .new-left-col .tags-liste {
      margin-top: 20px; }
      .new-detail .new-left-col .tags-liste .tag-new {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        border-width: 1px;
        border-style: solid;
        margin-bottom: 5px;
        padding-left: 6px;
        padding-right: 6px; }
  .new-detail .new-right-col {
    width: calc(100% - 360px); }
    .new-detail .new-right-col .h1 {
      font-size: 28px;
      text-align: left;
      margin-bottom: 15px; }
    .new-detail .new-right-col .h3 {
      text-align: left; }
    .new-detail .new-right-col .cta-new-c {
      text-align: center;
      margin-top: 25px; }
      .new-detail .new-right-col .cta-new-c .cta-new {
        font-size: 14px;
        padding: 15px 30px; }

/* Détail marques */
.liste-marques .h1 {
  color: #000;
  text-align: left;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px; }

.liste-marques .stitle {
  color: #000;
  font-weight: 300;
  font-size: 16px;
  text-transform: none;
  text-align: left; }

.liste-marques .bandeau-marque {
  position: relative; }
  .liste-marques .bandeau-marque .bandeau-detail-marque {
    border-top: 2px solid #ddd;
    padding-top: 30px; }
    .liste-marques .bandeau-marque .bandeau-detail-marque img {
      display: block;
      width: 100%;
      height: 230px;
      object-fit: cover; }
  .liste-marques .bandeau-marque .bandeau-marque-contenu {
    position: absolute;
    top: 30px;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu > div {
      width: calc(100% / 3 - 20px);
      text-align: center;
      margin-left: 10px;
      margin-right: 10px; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .head_pres {
      font-size: 12px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.35);
      padding: 35px 40px; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .brand_logo img {
      max-height: 180px;
      max-width: 180px; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .list_img_mag {
      margin-bottom: 0;
      max-width: 240px;
      margin-left: auto;
      margin-right: auto; }
      .liste-marques .bandeau-marque .bandeau-marque-contenu .list_img_mag .slick-slide > div {
        height: 100%; }
      .liste-marques .bandeau-marque .bandeau-marque-contenu .list_img_mag img {
        height: 100%;
        object-fit: cover;
        max-height: 180px; }

.liste-marques .main_content {
  padding-left: 30px;
  margin-bottom: 30px; }
  .liste-marques .main_content h2.h3 {
    font-size: 30px;
    color: #555555;
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 30px; }
  .liste-marques .main_content .list_product {
    display: flex;
    justify-content: center; }
    .liste-marques .main_content .list_product .slick-list {
      padding-bottom: 3rem; }
    .liste-marques .main_content .list_product .slick-dots {
      bottom: .2rem; }
      .liste-marques .main_content .list_product .slick-dots li button::before {
        width: 20px;
        height: 20px; }
    .liste-marques .main_content .list_product .slick-slide > div {
      height: 100%; }
    .liste-marques .main_content .list_product .card-product-marque {
      width: calc(100% / 3 - 40px);
      height: 100%;
      border-radius: 22px;
      background-color: #F4F4F6;
      padding: 16px;
      margin-left: 20px;
      margin-right: 20px; }
      .liste-marques .main_content .list_product .card-product-marque img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 12px; }
      .liste-marques .main_content .list_product .card-product-marque .title {
        font-size: 30px; }
      .liste-marques .main_content .list_product .card-product-marque .info {
        line-height: 1.3; }
  .liste-marques .main_content .news_and_vid {
    display: flex;
    justify-content: center; }
    .liste-marques .main_content .news_and_vid > div {
      width: calc(50% - 30px);
      margin-left: 15px;
      margin-right: 15px; }
    .liste-marques .main_content .news_and_vid .actu-card {
      position: relative; }
      .liste-marques .main_content .news_and_vid .actu-card .actu-img-bg {
        aspect-ratio: 465 / 155;
        background-position: center;
        object-fit: cover;
        min-height: 140px;
        max-width: 100%; }
      .liste-marques .main_content .news_and_vid .actu-card .date {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(100% / 3);
        min-width: 130px;
        text-align: left;
        color: #fff;
        background-color: #417F35;
        padding: 5px 10px; }
      .liste-marques .main_content .news_and_vid .actu-card .actu-desc .h4 {
        font-size: 21px;
        margin-top: 12px;
        text-transform: none;
        color: #417F35; }
      .liste-marques .main_content .news_and_vid .actu-card .actu-desc p {
        line-height: 1.6; }
    .liste-marques .main_content .news_and_vid .video-iframe {
      aspect-ratio: 605/340; }
  .liste-marques .main_content .button_blue,
  .liste-marques .main_content .button_green {
    font-size: 16px;
    text-transform: none;
    font-weight: 600;
    padding: 18px 22px;
    margin-top: 25px; }
  .liste-marques .main_content .liste-mag {
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto; }

/* Page détail news et marque */
.page-marque-detail .btn-cta-listing,
.page-news-detail .btn-cta-listing {
  position: absolute;
  top: 14px;
  right: 15px;
  z-index: 9; }
  .page-marque-detail .btn-cta-listing .btn-outline,
  .page-marque-detail .btn-cta-listing .button_green,
  .page-news-detail .btn-cta-listing .btn-outline,
  .page-news-detail .btn-cta-listing .button_green {
    position: relative;
    z-index: 1;
    padding: 10px 16px;
    height: auto;
    font-size: .8rem; }

.liste-marques .bandeau-marque.page-marque-detail .bandeau-detail-marque img {
  height: 100%;
  max-height: 865px; }

.page-news-detail {
  padding-top: 60px;
  border-top: 2px solid #ddd; }
  .page-news-detail .desc-new {
    font-size: 18px;
    line-height: 1.3; }

.liste-marques .bandeau-marque.page-marque-detail .bandeau-detail-marque {
  padding-top: 80px; }

@media (max-width: 1300px) {
  .bloc_actu .liste-news .card-offre,
  .bloc_actu .liste-news .news_card {
    width: calc(100% / 3 - 10px);
    margin-left: 5px;
    margin-right: 5px; }
  .news_block .news_content {
    margin-left: 5px;
    margin-right: 5px; } }

@media (max-width: 1199px) {
  .liste-marques .main_content .list_product .card-product-marque {
    width: calc(100% / 3 - 20px);
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px; }
  .liste-marques .bandeau-marque .bandeau-marque-contenu .head_pres {
    padding: 15px 20px; } }

@media (max-width: 991px) {
  .new-detail .new-left-col {
    margin-right: 20px; }
  .new-detail .new-right-col {
    width: calc(100% - 340px); }
  .liste-marques .bandeau-marque .bandeau-marque-contenu .head_pres {
    padding: 5px 5px; } }

@media (max-width: 767px) {
  .new-detail {
    flex-direction: column; }
    .new-detail .new-left-col {
      width: 100%;
      margin-bottom: 30px;
      margin-right: 0; }
    .new-detail .new-right-col {
      width: 100%; }
  .liste-marques .main_content h2.h3 {
    font-size: 22px; }
  .liste-marques .main_content .list_product .card-product-marque {
    width: calc(50% - 20px); }
    .liste-marques .main_content .list_product .card-product-marque:nth-child(3) {
      display: none; }
  .liste-marques .main_content .news_and_vid {
    flex-wrap: wrap; }
    .liste-marques .main_content .news_and_vid > div {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px; }
  .liste-marques .bandeau-marque .bandeau-marque-contenu > div {
    width: calc(50% - 20px); }
  .liste-marques .bandeau-marque .bandeau-marque-contenu .slider_mag {
    display: none; } }

@media (max-width: 727px) {
  .bloc_actu .liste-news .card-offre,
  .bloc_actu .liste-news .news_card {
    width: calc(50% - 10px); }
  .news_block .news_content {
    margin-left: 5px;
    margin-right: 5px; }
  .filter_header .text_label {
    display: block;
    line-height: 1.6; }
  .filter_header .custom-select {
    margin-left: 0; } }

@media (max-width: 598px) {
  .bloc_actu .liste-news .card-offre,
  .bloc_actu .liste-news .news_card {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  .news_block .news_title,
  .news_block .news_desc {
    height: auto; }
  .filter_header {
    flex-direction: column; }
    .filter_header form {
      margin-top: 6px;
      margin-left: 0; }
  #news .liste-news .news_content {
    padding-bottom: 0; } }

@media (max-width: 575px) {
  .liste-marques .main_content {
    padding-left: 15px; }
    .liste-marques .main_content .list_product .card-product-marque {
      width: 100%; }
      .liste-marques .main_content .list_product .card-product-marque:nth-child(2) {
        display: none; }
  .liste-marques .bandeau-marque .bandeau-marque-contenu {
    flex-direction: column; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu > div {
      width: 100%; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .head_pres_c {
      display: none; }
    .liste-marques .bandeau-marque .bandeau-marque-contenu .brand_logo_c {
      margin-bottom: 10px; }
  .liste-marques .bandeau-marque.page-marque-detail .bandeau-marque-contenu {
    position: static;
    flex-direction: column;
    margin-top: 20px; }
    .liste-marques .bandeau-marque.page-marque-detail .bandeau-marque-contenu .head_pres_c {
      display: block; }
    .liste-marques .bandeau-marque.page-marque-detail .bandeau-marque-contenu .brand_logo img {
      max-height: 100px; }
  .liste-marques .bandeau-marque.page-marque-detail .bandeau-detail-marque {
    min-height: 0;
    height: 0; } }

#pagination {
  position: relative; }

.pager_c ul {
  margin: 0;
  text-align: center; }

.pager_c li {
  display: inline-block;
  margin-left: 4px; }

.pager_c li .link,
.pager_c li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 35px;
  width: 35px;
  color: #000;
  background-color: #f4f4f6;
  border: 1px solid #b7c0db;
  border-radius: 50%; }

.pager_c li.navig .link,
.pager_c li.navig a {
  font-size: 13px; }

.pager_c li .link .icon,
.pager_c li a .icon {
  line-height: 0; }

.pager_c li.current .link,
.pager_c li.current .link:focus,
.pager_c li.current .link:hover,
.pager_c li.current a,
.pager_c li.current a:focus,
.pager_c li.current a:hover {
  color: #fff;
  background-color: #d02752; }

.pager_c li:not(.truncate) .link:focus,
.pager_c li:not(.truncate) .link:hover,
.pager_c li:not(.truncate) a:focus,
.pager_c li:not(.truncate) a:hover {
  background-color: #b7c0db; }

.pager_c li.truncate .link:focus,
.pager_c li.truncate .link:hover,
.pager_c li.truncate a:focus,
.pager_c li.truncate a:hover {
  cursor: pointer; }

#marques .pager_c {
  position: relative;
  margin-top: 20px; }

.sub_nav_content {
  position: absolute;
  z-index: 2;
  top: 45px;
  right: 0;
  left: 0;
  overflow: hidden;
  max-height: 0;
  transition: all .1s ease-in-out; }

#marques .sub_nav_content {
  top: 33px; }

.sub_nav_content .sub_nav {
  padding: 5px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  text-align: center; }

.truncate.shownav .sub_nav_content {
  max-height: 500px; }

.truncate.shownav > .link::after,
.truncate.shownav > .link::before {
  position: absolute;
  z-index: 3;
  left: 50%;
  width: 0;
  height: 0;
  content: '';
  border-style: solid; }

.truncate.shownav > .link::before {
  top: 37px;
  margin-left: -6px;
  border-width: 0 6px 9px 6px;
  border-color: transparent transparent #ccc transparent; }

.truncate.shownav > .link::after {
  top: 38px;
  margin-left: -5px;
  border-width: 0 5px 8px 5px;
  border-color: transparent transparent #fff transparent; }

.sub_nav li {
  margin: 1px; }

.pager_c .sub_nav .link {
  font-size: 9px;
  line-height: 22px;
  width: 22px;
  height: 22px;
  min-height: 22px;
  padding-right: 0;
  padding-left: 0;
  text-align: center; }

/* Spécifique au minisite 6 */
body {
  font-family: 'Montserrat';
  color: #141414; }

.container-lg {
  max-width: 1200px; }

/* Aside sticky favoris */
.sticky-favori {
  position: fixed;
  right: -1px;
  top: 20rem;
  text-align: center;
  z-index: 9;
  border: 1px solid #fff; }
  .sticky-favori a.btn_favo {
    display: flex;
    flex-direction: column;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 12px; }
    .sticky-favori a.btn_favo .icon {
      font-size: 40px;
      margin-bottom: 8px; }
    .sticky-favori a.btn_favo:hover {
      color: #9FA8BD;
      background-color: #fff; }

.btn_favo_minisite .icon-favorite {
  display: none; }

.btn_favo_minisite .icon-favorite-none {
  display: block; }

.btn_favo_minisite.mag_favorite .icon-favorite {
  display: block; }

.btn_favo_minisite.mag_favorite .icon-favorite-none {
  display: none; }

#btn_back_c {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center; }

#btn_back_c .back_btn:hover {
  margin-left: -10px; }

#btn_back_c .icon,
#btn_back_c .text {
  color: #777; }

#btn_back_c .text {
  font-size: 16px;
  margin-right: 10px;
  margin-left: 10px;
  text-transform: uppercase; }

#btn_back_c .btn_reseaux {
  display: flex;
  margin-left: auto;
  align-items: center; }

#btn_back_c .btn_reseaux .icon {
  font-size: 30px;
  margin: 0 5px; }

#btn_back_c .btn_reseaux .icon-facebook-rounded {
  color: #3b5998; }

#btn_back_c .btn_reseaux .icon-twitter-rounded {
  color: #1da1f2; }

#btn_back_c .btn_reseaux .icon-linkedin-rounded {
  color: #0077b5; }

#btn_back_c .btn_reseaux .icon:hover {
  opacity: .8; }

#bloc_instagram {
  padding-top: 2rem;
  text-align: center;
  background-color: #fff;
  margin-bottom: 22px; }

body.color_v1 #bloc_instagram .title a:hover,
body.color_v2 #bloc_instagram .title a:hover,
body.color_v3 #bloc_instagram .title a:hover {
  color: #555; }

#bloc_instagram h2 .highlight {
  text-decoration: underline;
  font-size: 28px;
  font-weight: 400; }

#bloc_instagram_content {
  margin-left: -50px;
  margin-right: -50px; }

#bloc_instagram_content li {
  list-style-type: none; }

#bloc_instagram_content a > img,
#bloc_instagram_content .lien-off > img {
  height: 100%;
  width: 100%;
  max-height: 322px;
  object-fit: cover;
  aspect-ratio: 1; }

#bloc_instagram_content .instagram_video {
  position: relative; }

#bloc_instagram_content .instagram_video::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color .3s; }

#bloc_instagram_content .instagram_video::after {
  content: "\EA72";
  font-family: kfont;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 5.4rem;
  font-size: 2.6rem;
  padding-left: .6rem;
  border-radius: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  transition: opacity .3s; }

#bloc_instagram_content .instagram_video:hover::before {
  background-color: transparent; }

#bloc_instagram_content .instagram_video:hover::after {
  opacity: 0; }

/* Section bloc fil actualités FB / LinkedIn */
.actus-reseaux {
  margin-bottom: 0; }
  .actus-reseaux .h2 {
    margin-bottom: 30px; }

.list-post-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .list-post-card .col-left-post,
  .list-post-card .col-right-post {
    width: calc(100% / 2 - 20px);
    max-height: 1400px;
    overflow-x: auto;
    scrollbar-color: #aaa #fff;
    scrollbar-width: thin; }
    .list-post-card .col-left-post::-webkit-scrollbar,
    .list-post-card .col-right-post::-webkit-scrollbar {
      width: 5px; }
    .list-post-card .col-left-post::-webkit-scrollbar-track,
    .list-post-card .col-right-post::-webkit-scrollbar-track {
      background-color: darkgrey; }
    .list-post-card .col-left-post::-webkit-scrollbar-thumb,
    .list-post-card .col-right-post::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      border-radius: 10px; }

.post-card {
  display: block;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
  border: 1px solid #141414; }
  .post-card:hover {
    opacity: .8; }
  .post-card .header-actus-reseaux {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 6px; }
  .post-card .date-actus-reseaux {
    font-size: 16px;
    opacity: .6; }
  .post-card img {
    margin-top: 20px; }

#sticky_right {
  position: fixed;
  z-index: 10;
  top: 120px;
  right: 0;
  width: 230px;
  text-align: right; }

#sticky_right li {
  display: inline-block;
  margin: 0; }

#sticky_right li a {
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  display: block;
  width: 216px;
  min-height: 40px;
  padding-left: 10px;
  text-align: left;
  vertical-align: middle;
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px 0 0 10px; }

#sticky_right li a:hover {
  width: 235px;
  padding-right: 20px; }

#map {
  z-index: 1;
  width: 100%;
  height: 100%; }

#map #popup_content {
  padding: 5px; }

#map #popup_content .name {
  font-weight: 700;
  margin-bottom: 5px; }

#map #popup_content .tel {
  margin-top: 5px; }

#map #popup_content .phone:hover {
  opacity: .8; }

#map .street_button a {
  font-size: 11px;
  line-height: 20px;
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  vertical-align: middle;
  opacity: .7;
  color: #000;
  background: #fff; }

#map .street_button .icon-itineraire {
  font-size: 20px;
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  color: #000; }

#map .street_button a:hover {
  opacity: 1; }

#map.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  bottom: -20px !important;
  left: -76px !important; }

.address_block {
  line-height: 230px;
  padding: 0 20px;
  color: #fff; }

.address_block .ssstitle,
.schedule_block .ssstitle {
  margin-bottom: 7px; }

.address_block .text {
  font-size: 13px;
  font-weight: 300; }

.address_block .phone {
  font-size: 17px;
  margin: 7px 0; }

.address_block .phone a {
  color: #fff; }

.icons_mag {
  line-height: 30px;
  height: 30px;
  margin-top: 10px; }

.icons_mag .icon {
  font-size: 20px;
  line-height: 38px;
  display: inline-block;
  width: 34px;
  height: 34px;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: #fff; }

#store_info .contact .icon {
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle; }

.schedule_block {
  padding: 20px;
  color: #fff; }

.schedule_block .day li {
  line-height: 23px;
  position: relative;
  margin: 0;
  padding: 0; }

.schedule_block .day li .hour_center {
  position: absolute;
  top: 0;
  left: 60%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.schedule_block .day li .hour.am {
  position: absolute;
  top: 0;
  left: 80px;
  min-width: 71px;
  text-align: center;
  letter-spacing: -1px; }

.schedule_block .day li .hour.pm {
  position: absolute;
  top: 0;
  left: 170px;
  min-width: 71px;
  text-align: center; }

.schedule_block .day li + li:not(.info_rdv) {
  border-top: 1px solid #fff; }

.schedule_block .day li.info_rdv {
  float: right; }

#store_service .height_230 {
  padding: 20px;
  color: #fff; }

#store_service .stitle {
  margin: 30px 0 7px 0;
  text-align: center; }

#store_service .button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

#store_service .button.minwidth {
  min-width: 216px;
  padding-right: 0; }

#store_info .cartemo {
  display: inline-block;
  float: right;
  width: 106px;
  height: 30px;
  margin-top: 4px;
  margin-right: -70px;
  background-size: cover; }

#news .push_promo a.btn3:hover {
  opacity: .7; }

#news .news_content .tag,
#news_monopti .news_content .tag {
  display: inline-block;
  padding: 10px 14px;
  text-transform: uppercase;
  color: #fff; }

#news #push_promo .resize_text,
#news .news_content p,
#news_monopti .news_content p {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.3em;
  overflow: hidden;
  max-height: 94px; }

#news .news_content .button,
#news_monopti .news_content .button {
  position: absolute;
  bottom: 15px;
  left: 25px; }

#news .news_img,
#news_monopti .news_img {
  position: relative; }

.triangle.contain_bg {
  background-position-x: left; }

#push_promo .news_img {
  background-position-x: left; }

#news .news_img.triangle:after,
#news_monopti .news_img.triangle:after {
  position: absolute;
  top: calc(50% - 18px);
  left: 0;
  display: block;
  width: 0;
  height: 0;
  content: '';
  border-width: 18px 0 18px 18px;
  border-style: solid;
  border-color: transparent transparent transparent #fff; }

.bloc_actu .liste-news .card-offre:hover {
  box-shadow: none; }

.bloc_actu .liste-news .card-offre .img-offre .date-offre {
  left: auto;
  color: #fff;
  text-align: right;
  font-weight: 500; }

.button_bgwh_color2.button_offer {
  margin-top: 15px;
  background-color: #fff; }

#magazine_mo .magazine_contenu .book {
  position: absolute;
  right: 22px;
  bottom: 80px; }

#magazine_mo .magazine_contenu .blue_box {
  display: flex;
  padding: 17px 10px 18px 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #35c3c8; }

#magazine_mo .magazine_contenu .icon-doc {
  font-size: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  color: #fff; }

#magazine_mo .magazine_contenu .btn_abonnement {
  font-size: 25px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 250px;
  padding: 5px 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border: 1px #fff solid;
  background-color: rgba(0, 0, 0, 0.2); }

#magazine_mo .magazine_contenu .btn_abonnement:hover {
  color: #000;
  background-color: rgba(255, 255, 255, 0.5); }

#team {
  text-align: center; }

#team .stitle {
  margin-bottom: 25px;
  color: #555; }

#team .employee_fiche {
  margin-top: 15px; }

#team .employee_name {
  font-size: 16px;
  margin-top: 10px;
  -webkit-hyphens: none;
  hyphens: none;
  -ms-hyphens: none; }

#team .employee_name .highlight {
  display: block;
  text-transform: uppercase; }

#team .employee_status {
  font-size: 12px;
  font-weight: 300; }

#team li {
  width: 175px;
  margin: 0 1em; }

#team li img {
  width: 175px; }

#video .vid_iframe {
  width: 720px;
  height: 405px;
  margin: 0 auto; }

#brands_slider {
  padding-top: 30px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #fff; }

#brands_slider .title-lined {
  margin-bottom: 25px; }

.slider_marques {
  margin-left: -20px;
  margin-right: -20px; }

.slider_marques .slick-track {
  margin-left: auto;
  margin-right: auto; }

.slider_marques .slick-slide {
  margin-left: 20px;
  margin-right: 20px; }

.marque_card {
  display: block;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  transition: box-shadow .3s; }

a.marque_card:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5); }

.marque_card figure {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 0 auto; }

.marque_card figure img {
  object-fit: contain;
  max-height: 100%;
  margin-bottom: 20px; }

.marque_card_container .title {
  display: block;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #141414;
  text-align: center;
  text-transform: uppercase; }

#certif {
  width: 100%;
  background: #fff; }

.certif_content {
  width: 1200px; }

#certif .stitle {
  margin-top: 20px;
  text-align: center;
  color: #555; }

#certif .list_certifs {
  position: relative;
  margin: 20px auto;
  text-align: center; }

#certif .cert {
  width: calc(100% / 4);
  height: 120px;
  margin-top: 20px; }

#certif .cert img {
  height: 100%; }

#rassurance {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center; }

#rassurance .stitle {
  color: #555; }

#rassurance .center_title {
  margin-top: 20px;
  margin-bottom: 30px; }

#rassurance .rond_rassurance {
  line-height: 138px;
  width: 144px;
  height: 144px;
  margin: 25px auto 0 auto;
  transition: all .3s ease-in-out;
  border: 3px dotted #d4dbeb;
  border-radius: 50%; }

#rassurance .rond_rassurance:hover {
  border: 3px dotted #555; }

#rassurance .rond_rassurance .rond_inside {
  line-height: 130px;
  overflow: hidden;
  width: 130px;
  height: 130px;
  margin-top: 4px;
  margin-left: 4px;
  transition: all .3s ease-in-out;
  border-radius: 50%;
  background-color: #fff; }

#rassurance .text_rassurance {
  font-family: Noway,sans-serif;
  font-size: 12px;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  color: #595959; }

#rassurance .rond_rassurance .text_hover {
  visibility: hidden;
  transition: all .3s ease-in-out;
  opacity: 0; }

#rassurance .rond_rassurance:hover .text_hover {
  visibility: visible;
  opacity: 1; }

#rassurance .icon {
  font-size: 60px;
  display: block;
  margin-bottom: -5px;
  transition: all .3s ease-in-out; }

#rassurance .icon-lunettes {
  font-size: 80px;
  margin-top: -10px;
  margin-bottom: -15px; }

#rassurance .rond_rassurance:hover .icon,
#rassurance .rond_rassurance:hover .text_rassurance {
  color: #fff; }

.seo_localisation {
  line-height: 32px;
  height: 32px;
  margin-left: 14px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #f5f5f5; }

.seo_localisation .breadcrumb,
.seo_localisation .breadcrumb a {
  color: #000; }

.seo_localisation .breadcrumb a:focus,
.seo_localisation .breadcrumb a:hover {
  text-decoration: underline; }

.seo_localisation .breadcrumb {
  display: inline-block; }

.seo_localisation .expand_link {
  float: right;
  margin-right: 30px; }

.seo_localisation .expand_link:hover {
  color: #9fa8bd; }

.seo_localisation .expand_box {
  overflow: hidden;
  max-height: 0;
  transition: all .5s ease;
  background-color: #f5f5f5; }

.seo_localisation .expand_box.expand {
  max-height: 3000px; }

.seo_localisation .localisation {
  margin-top: 14px;
  padding-bottom: 16px; }

.seo_localisation .localisation .title {
  font-weight: 700; }

.seo_localisation .localisation li {
  line-height: 1.6;
  margin-left: 0; }

.seo_localisation .localisation li a {
  color: #000; }

.seo_localisation .localisation li a:hover {
  color: #959595; }

#formulaire-reduct {
  max-width: 850px;
  margin: 0 auto; }

div.content .formulaire .row {
  line-height: 45px; }

.formulaire .select {
  display: inline-block; }

.formulaire .textarea {
  display: block;
  border: 1px solid #a6a6ae; }

.formulaire .label-title {
  font-size: 13px;
  display: inline-block;
  width: 175px;
  text-transform: uppercase;
  -webkit-hyphens: none;
  hyphens: none;
  -ms-hyphens: none; }

.formulaire .label-title.no-width {
  width: auto;
  border: none; }

.formulaire input[type=email],
.formulaire input[type=select],
.formulaire input[type=tel],
.formulaire input[type=text],
.formulaire select {
  line-height: 36px;
  width: 300px;
  height: 36px;
  margin-top: 5px;
  margin-right: 50px;
  margin-bottom: 5px;
  padding-left: 10px;
  border: 1px solid #a6a6ae; }

.formulaire select {
  padding-left: 5px; }

.formulaire textarea {
  width: calc(100% - 50px);
  padding: 5px; }

.formulaire .links {
  margin-top: 20px;
  text-align: center; }

.formulaire .back_hist {
  margin-right: 20px; }

.formulaire input[type=submit] {
  cursor: pointer;
  transition: all .3s ease-in-out; }

.formulaire input[type=file] {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  opacity: 0; }

.formulaire .label-img {
  padding: 10px;
  transition: all .3s ease-in-out;
  border-radius: 5px; }

#master_brand .h2,
#master_brand h2 {
  font-family: inherit;
  font-weight: 700;
  margin-top: 20px;
  padding-bottom: 0;
  color: #555;
  border: none; }

#master_brand .h3,
#master_brand h3 {
  margin-bottom: 14px;
  margin-left: 5px;
  text-transform: none; }

#master_brand .title_c {
  margin-bottom: 30px;
  border-bottom: 1px solid #555; }

#master_brand .h3 .highlight,
#master_brand h3 .highlight {
  font-weight: 300; }

#master_brand li {
  margin-bottom: 40px;
  text-align: center; }

#master_brand .list_marques a {
  display: inline-block; }

#master_brand .icon_logo {
  width: 29px; }

#master_brand .list_marques .img_brand_c {
  height: 100px; }

#master_brand .list_marques .card-brand {
  transition: all .3s ease-in-out;
  border-radius: 0;
  border: 1px solid #e2dddd;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); }

#master_brand .card-brand .logo_marque {
  display: block;
  max-width: 240px;
  max-height: 80px;
  margin: 0 auto;
  transition: all .3s ease-in-out;
  -webkit-transform: scale(0.9);
  transform: scale(0.9); }

#master_brand .card-brand:hover .logo_marque {
  -webkit-transform: scale(1);
  transform: scale(1); }

#master_brand .list_marques .text {
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #bbb; }

.list_mini_news:not(.list_news) li {
  position: relative;
  width: calc(50% - 12px);
  margin-left: 0; }

.list_mini_news.list_news {
  margin-top: 20px; }

.list_mini_news.list_news li .abs_container {
  margin-right: 0; }

.list_mini_news .abs_container:nth-child(odd) {
  margin-right: 10px; }

.list_mini_news:not(.list_news) .abs_container .img_news {
  position: relative;
  display: block;
  min-height: 102px;
  background-color: #ccc; }

.list_mini_news .abs_container .second_block {
  line-height: 15px;
  overflow: hidden;
  height: 120px;
  margin: 0 auto 10px;
  padding-bottom: 10px;
  text-align: justify;
  background-color: #fff; }

.list_mini_news.list_news .abs_container .second_block {
  position: relative;
  height: 0;
  margin-bottom: 20px;
  padding-top: 44%; }

.list_mini_news.list_news .abs_container .second_block .abs_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.list_mini_news .abs_container .contenu {
  width: 85%;
  margin: 0 auto; }

.list_mini_news.list_news .abs_container .contenu {
  width: 100%;
  margin: 0;
  padding: 10px 18px; }

.list_mini_news.list_news .abs_container .first_block {
  position: relative;
  height: 0;
  padding-top: 39.7%;
  background-color: #ccc; }

.list_mini_news li.type_fournisseur .first_block {
  border-bottom: 6px solid #248128; }

.list_mini_news li.type_opticien .first_block {
  position: relative;
  border-bottom: 6px solid #c03779; }

.list_mini_news li.type_cdo .first_block {
  border-bottom: 6px solid #D22350; }

#active_news .date,
.list_mini_news .abs_container .first_block .date {
  font-family: Montserrat,sans-serif;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: -2px;
  left: 0;
  padding: 3px 4px 0 4px;
  color: #fff;
  background-color: #c03779; }

#active_news .offre .date,
.list_mini_news .offre .abs_container .first_block .date {
  background-color: #f2a628; }

.list_mini_news li.type_cdo .first_block .date {
  display: none; }

.list_mini_news.list_news .abs_container .first_block {
  width: 100%; }

.list_mini_news.list_news li .abs_container {
  position: relative;
  box-shadow: 0 0 10px 0 #cfcfcf;
  -o-box-shadow: 0 0 10px 0 #cfcfcf; }

.list_mini_news.list_news .abs_container .hover_block {
  right: 0;
  bottom: 0; }

#news_content .list_mini_news .abs_container .sub_title,
.list_mini_news .abs_container .sub_title {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  margin: auto;
  padding-top: 10px;
  text-align: left;
  text-transform: uppercase; }

.list_mini_news .abs_container p {
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
  height: 60px;
  margin-bottom: 0; }

.list_mini_news .abs_container .hover_block {
  position: absolute;
  top: 0;
  right: 9px;
  bottom: 10px;
  left: 0;
  transition: all .3s ease-in-out;
  text-align: center;
  opacity: 0; }

.list_mini_news .abs_container:hover .hover_block {
  opacity: 1;
  background-color: rgba(53, 195, 200, 0.4); }

.list_mini_news .abs_container .hover_block a {
  color: #fff; }

.list_mini_news .abs_container .hover_block .icon {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  padding: 20px 20px 18px 20px;
  transition: all .3s ease-in-out;
  -webkit-transform: rotate(45deg) scale(0.6) translate(-75%);
  transform: rotate(45deg) scale(0.6) translate(-75%);
  color: #fff;
  background-color: #35c3c8; }

.list_mini_news .abs_container .hover_block:hover .icon {
  -webkit-transform: rotate(45deg) scale(1) translate(-50%);
  transform: rotate(45deg) scale(1) translate(-50%); }

.list_mini_news .abs_container .hover_block .icon:hover {
  background-color: #00a4a9; }

#news .btn_news {
  text-align: center; }

.bg_content {
  padding: 10px 28px;
  background: #fff; }

.sort_by_type {
  text-align: right; }

.sort_by_tag {
  line-height: 25px; }

.sort_by_tag ul,
.sort_by_tag ul > li {
  display: inline-block;
  margin: auto; }

.text_label {
  font-size: 12px;
  font-weight: 400; }

.bar {
  height: 1px;
  margin: 10px 0;
  background: #b3b3b3; }

.type {
  font-size: 11px;
  padding: 3px 5px;
  white-space: nowrap;
  color: #fff; }

.type.all_filter {
  background-color: #35c3c8; }

#current_news.fournisseur .tag,
.type.fournisseur {
  background-color: #248128; }

#current_news.cdo .tag,
.type.cdo {
  background-color: #D22350; }

#current_news.opticien .tag,
.type.opticien {
  background-color: #c03779; }

#current_news.cdo .date {
  display: none; }

.type.offer {
  background-color: #f2a628; }

#current_news .tag,
.tag {
  font-size: 11px;
  margin-right: 5px;
  padding: 3px 8px;
  transition: all .3s ease-in-out;
  white-space: nowrap;
  color: #fff;
  background-color: #d9dce5; }

#current_news .tag:focus,
#current_news .tag:hover,
.tag:focus,
.tag:hover {
  opacity: .8;
  color: #fff; }

#offers_news #current_news_content .tag:hover {
  opacity: .8;
  background-color: #c03779; }

#offers_news #current_news_content .tag,
.body_news #current_news_content .tag {
  color: #fff;
  border-radius: 0; }

#offers_news .tag,
.body_news .tag {
  color: #555;
  border-radius: 10px; }

#offers_news .tag:focus,
#offers_news .tag:hover,
.body_news .tag:focus,
.body_news .tag:hover {
  opacity: 1;
  color: #555;
  background-color: rgba(217, 220, 229, 0.7); }

#news .tag:focus,
#news .tag:hover,
#news_monopti .tag:focus,
#news_monopti .tag:hover {
  opacity: .8; }

.filter_header {
  position: relative; }

.sort_by_type,
.sort_container {
  line-height: 32px;
  min-height: 32px; }

.sort_by_type {
  position: absolute;
  top: 0;
  right: 0; }

.sort_by_type a:focus,
.sort_by_type a:hover {
  opacity: .8;
  color: #fff; }

.filtre_news {
  display: inline-block; }

.sort_container .ui-selectmenu-button {
  line-height: 32px;
  position: relative;
  display: inline-block;
  height: 32px;
  margin-left: 10px;
  padding: 0 70px 0 15px;
  text-align: left;
  border: 1px solid #000;
  border-radius: 0;
  background-color: #fff; }

.sort_container .ui-selectmenu-button:active,
.sort_container .ui-selectmenu-button:focus,
.sort_container .ui-selectmenu-button:hover {
  color: #2b2b2b;
  background-color: #f6f6f6; }

.ui-button .ui-icon {
  line-height: 20px;
  position: absolute;
  top: 6px;
  right: 3px;
  width: 20px;
  height: 20px;
  text-align: center;
  text-indent: inherit;
  background: 0 0; }

.sort_container .ui-button .ui-icon:before {
  font-family: kfont !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  width: 0;
  height: 0;
  content: '';
  transition: all .2s ease-in-out;
  text-transform: none;
  border-width: 7px 6.5px 0 6.5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.sort_container .ui-button.ui-selectmenu-button-open .ui-icon:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.ui-menu .ui-menu-item {
  background: #fff; }

.ui-selectmenu-text {
  font-style: italic;
  margin-right: 5px; }

.ui-selectmenu-button.ui-button {
  width: auto; }

#news_content .h2,
#news_content h2 {
  margin: 0 auto 1em;
  text-align: center;
  color: #555; }

#news_content .bloc_actu ul.list_news > li {
  visibility: hidden;
  transition-duration: .5s;
  transition-property: opacity;
  opacity: 0; }

#news_content .bloc_actu ul.list_news > li.visible {
  display: block;
  visibility: visible;
  opacity: 1; }

#news_content .bloc_actu ul.list_news > li:not(.visible) {
  width: 0;
  height: 0;
  padding: 0; }

#current_news_content .img_header .cover_bg {
  position: relative;
  height: 430px; }

#current_news_content .img_header .cover_bg.mini {
  height: 100px; }

#current_news_content h2 {
  margin-top: 30px;
  color: #555; }

#current_news_content .date {
  font-family: Montserrat,sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.3;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 9px 14px;
  color: #fff; }

#current_news_content .list_mini_news .date {
  font-weight: 400;
  line-height: 1;
  padding: 3px 4px 0 4px; }

#current_news.cdo .border_bottom,
#current_news.cdo .cover_bg .text,
#current_news.cdo .date {
  background-color: #D22350; }

#current_news.fournisseur .border_bottom,
#current_news.fournisseur .cover_bg .text,
#current_news.fournisseur .date {
  background-color: #248128; }

#current_news.opticien .border_bottom,
#current_news.opticien .cover_bg .text,
#current_news.opticien .date {
  background-color: #c03779; }

#current_news .border_bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 8px; }

#current_news .cover_bg .text {
  font-family: Montserrat,sans-serif;
  font-size: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 9px 14px;
  text-transform: uppercase;
  color: #fff; }

#current_news .cover_bg .text .highlight {
  font-weight: 700; }

#current_news .white_box {
  padding: 25px 16px;
  color: #555;
  background-color: #fff; }

#current_news h1 {
  font-size: 28px;
  font-weight: 400; }

#current_news.cdo h1 {
  color: #D22350; }

#current_news.fournisseur h1 {
  color: #248128; }

#current_news.opticien h1 {
  color: #c03779; }

#current_news .stitle {
  font-size: 16px;
  margin-top: 1em;
  text-transform: uppercase; }

#current_news .text {
  line-height: 1.3;
  margin-top: 20px;
  text-align: justify; }

#offers_news .filtre_news[data-filter=cdo],
#offers_news .filtre_news[data-filter=fournisseur] {
  display: none; }

#offers_news .list_mini_news li.offre .first_block {
  border-bottom: 6px solid #f2a628; }

#offers_news .list_mini_news .abs_container .hover_block .icon-download {
  font-size: 40px;
  padding: 10px 10px 6px 10px; }

#cms.body_merci #rassurance {
  padding-top: 35px;
  border-top: 1px solid #555; }

#insurance .h2,
#insurance h2,
#paiement .h2,
#paiement h2 {
  margin-bottom: 50px;
  color: #555; }

#insurance .content ul,
#paiement .content ul {
  list-style-type: disc; }

#insurance li,
#paiement li {
  line-height: 25px; }

#insurance .main_content,
#paiement .main_content {
  padding: 25px; }

#insurance .insurance_list {
  column-count: 3; }

#paiement .paiement_list {
  column-count: 2; }

.body_legal h2 {
  color: #555; }

.formulaire.container {
  border-bottom: 1px solid #717171; }

.formulaire .stitle {
  margin-bottom: 25px;
  color: #717171; }

.hidden,
figcaption {
  display: none; }

.picto-cross {
  position: absolute;
  top: 14px;
  left: 3px;
  display: block;
  width: 24px;
  height: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background: #000; }

.picto-cross:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 24px;
  height: 2px;
  margin: 0;
  content: '';
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  background: #000; }

#marques .pager_c {
  position: relative;
  margin-top: 20px; }

.pager_c ul {
  margin: 0;
  text-align: center; }

.pager_c li {
  display: inline-block;
  margin-left: 4px; }

.pager_c li .link,
.pager_c li a {
  font-size: 16px;
  line-height: 22px;
  display: block;
  height: 22px;
  padding-right: 7px;
  padding-left: 7px;
  color: #000;
  background-color: #b7c0db; }

.pager_c li.navig .link,
.pager_c li.navig a {
  font-size: 13px;
  line-height: 24px; }

.pager_c li.current .link,
.pager_c li.current .link:focus,
.pager_c li.current .link:hover,
.pager_c li.current a,
.pager_c li.current a:focus,
.pager_c li.current a:hover {
  color: #fff;
  background-color: #d02752; }

.pager_c li:not(.truncate) .link:focus,
.pager_c li:not(.truncate) .link:hover,
.pager_c li:not(.truncate) a:focus,
.pager_c li:not(.truncate) a:hover {
  background-color: #9299ae; }

.pager_c li.truncate .link:focus,
.pager_c li.truncate .link:hover,
.pager_c li.truncate a:focus,
.pager_c li.truncate a:hover {
  cursor: pointer; }

.link {
  position: relative; }

.sub_nav_content {
  position: absolute;
  z-index: 2;
  top: 45px;
  right: 0;
  left: 0;
  overflow: hidden;
  max-height: 0;
  transition: all .1s ease-in-out; }

#marques .sub_nav_content {
  top: 33px; }

.sub_nav_content .sub_nav {
  padding: 5px;
  text-align: left;
  border: 1px solid #e3e3e3;
  background-color: #fff; }

.truncate.shownav .sub_nav_content {
  max-height: 500px; }

.truncate.shownav > .link::after,
.truncate.shownav > .link::before {
  position: absolute;
  z-index: 3;
  left: 50%;
  width: 0;
  height: 0;
  content: '';
  border-style: solid; }

.truncate.shownav > .link::before {
  bottom: -11px;
  margin-left: -6px;
  border-width: 0 6px 9px 6px;
  border-color: transparent transparent #ccc transparent; }

.truncate.shownav > .link::after {
  bottom: -12px;
  margin-left: -5px;
  border-width: 0 5px 8px 5px;
  border-color: transparent transparent #fff transparent; }

.sub_nav li {
  margin: 1px; }

.pager_c .sub_nav .link {
  font-size: 10px;
  line-height: 20px;
  width: 20px;
  height: auto;
  min-height: 20px;
  padding-right: 0;
  padding-left: 0;
  text-align: center; }

.kyxar {
  font-size: 9px;
  line-height: 25px;
  position: absolute;
  z-index: 10;
  right: 10px;
  bottom: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0;
  white-space: nowrap; }

.kyxar a {
  font-size: 9px;
  position: relative;
  text-decoration: none;
  letter-spacing: 1px;
  color: #555; }

.kyxar a .none {
  position: absolute;
  right: 0;
  bottom: 13px;
  overflow: hidden;
  height: 0; }

.kyxar a .none span {
  font-size: 9px;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.6); }

.kyxar a:focus .none,
.kyxar a:hover .none {
  height: 28px; }

@media (max-width: 1300px) {
  .container {
    width: 1024px; }
  #header .main_menu.right {
    margin-left: 0; }
  .schedule_block {
    padding-right: 5px;
    padding-left: 5px;
    color: #fff; }
  .schedule_block .day li .hour.am {
    left: 68px; }
  .schedule_block .day li .hour.pm {
    left: 155px; }
  #news_monopti .btn_abonnement {
    width: 220px; }
  .list_mini_news .abs_container .sub_title {
    font-size: 12px;
    padding-top: 8px; }
  .list_mini_news .abs_container p {
    margin-top: 5px; }
  #magazine_mo .magazine_contenu .btn_abonnement {
    width: 180px; }
  #store_info .cartemo {
    margin-right: -40px; }
  #store_service .button {
    min-width: 150px; } }

@media (max-width: 1150px) {
  .container {
    width: 960px; }
  #presentation .speciality ul {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 10px;
    column-gap: 10px; }
  #store_service .title {
    font-size: 23px; }
  .schedule_block .day li .hour.am {
    left: 62px; }
  .schedule_block .day li .hour.pm {
    left: 145px; }
  #news .news_content .button,
  #news_monopti .news_content .button {
    bottom: 15px; }
  #store_info .cartemo {
    width: 90px;
    height: 25px;
    margin-right: -25px; } }

@media (max-width: 990px) {
  .container {
    width: 728px; }
  section {
    margin-bottom: 40px; }
  .mobile {
    display: block; }
  .desktop {
    display: none; }
  #header {
    height: 60px; }
  #header #logo a img.logo {
    max-height: 60px; }
  #header .main_menu {
    display: none; }
  #header #logo {
    display: block;
    float: none;
    width: 20%;
    margin: auto; }
  #header #logo.alt_logo,
  #header .name_alt_logo {
    line-height: 62px;
    width: 80%; }
  .margin_left_20 {
    margin-left: 0; }
  #sticky_right {
    width: 65px; }
  #sticky_right li a > span.text,
  #sticky_right li a.schedule_status.open .text.ferme,
  #sticky_right li a.schedule_status.open .text.ouvert {
    display: none; }
  #sticky_right li a.schedule_status.close,
  #sticky_right li a.schedule_status.open {
    line-height: 40px;
    height: 40px;
    padding-top: 0; }
  #sticky_right li a.schedule_status {
    line-height: 40px;
    padding-bottom: 0; }
  #sticky_right li a {
    width: 35px; }
  #sticky_right li a:hover {
    width: 45px; }
  #sticky_right li a.higher {
    line-height: 40px;
    min-height: 40px; }
  .schedule_block {
    padding-right: 20px;
    padding-left: 20px; }
  .schedule_block .day li .hour.am {
    left: 105px; }
  .schedule_block .day li .hour.pm {
    left: 225px; }
  .list_mini_news .abs_container .second_block {
    height: 130px; }
  .list_mini_news .abs_container .sub_title {
    padding-top: 5px; }
  .list_mini_news .abs_container p {
    font-size: 11px;
    margin-top: 5px; }
  .list_mini_news.list_news .abs_container .second_block {
    padding-top: 60%; }
  #store_info .cartemo {
    width: 106px;
    height: 30px;
    margin-right: -70px; }
  .list-post-card .col-left-post,
  .list-post-card .col-right-post {
    width: 50%; }
  #insurance .insurance_list {
    column-count: 2; } }

@media (max-width: 727px) {
  .container {
    width: 600px; }
  .sticky-favori a {
    padding: 5px; }
    .sticky-favori a .icon {
      font-size: 30px;
      margin-bottom: 3px; }
  #presentation .title {
    font-size: 22px; }
  #news_monopti .news_content p {
    overflow: hidden;
    max-height: 105px; }
  .schedule_block .day li .hour.am {
    left: 80px; }
  .schedule_block .day li .hour.pm {
    left: 170px; }
  .formulaire input[type=email],
  .formulaire input[type=select],
  .formulaire input[type=tel],
  .formulaire input[type=text],
  .formulaire select {
    margin-right: 15px; }
  .formulaire textarea {
    width: calc(100% - 15px);
    height: 150px;
    border: 1px solid #a6a6ae; }
  #news .button_c_news {
    text-align: center; }
  #news .button_c_news .read_news {
    display: none; }
  #news .button_c.btn_news {
    padding: 15px 0; }
  #current_news .cover_bg .text {
    font-size: 16px;
    padding: 7px; }
  .list_mini_news.list_news .abs_container .second_block {
    padding-top: 50%; }
  #video .vid_iframe {
    width: 600px;
    height: 338px; } }

@media (max-width: 598px) {
  .container {
    width: 100%;
    max-width: calc(100% - 1.5rem); }
  section {
    margin-bottom: 20px; }
  #mini-header {
    display: none; }
  #header #logo {
    width: 45%; }
  .triangle.contain_bg {
    background-position: top center; }
  #news .news_img:after {
    top: 0;
    left: calc(50% - 18px);
    border-width: 18px 18px 0 18px;
    border-color: #fff transparent transparent transparent; }
  #news .news_content {
    height: auto;
    padding-bottom: 70px; }
  #news .news_img.triangle::after,
  #news_monopti .news_img.triangle::after {
    top: 0;
    left: calc(50% - 18px);
    border-width: 18px 18px 0 18px;
    border-color: #fff transparent transparent transparent; }
  #news_monopti .news_content p {
    max-height: 90px; }
  #certif .list_certifs:after {
    display: none; }
  #certif .cert {
    width: calc(100% / 2); }
  #certif .stitle {
    font-size: 18px; }
  #news_content .h2,
  #news_content h2 {
    font-size: 20px; }
  .list_mini_news.list_news .abs_container .second_block {
    padding-top: 55%; }
  .list_mini_news.list_news .abs_container .second_block {
    padding-top: 55%; }
  .sort_by_type {
    position: static;
    margin-top: 10px;
    text-align: left; }
  #current_news_content h2 {
    font-size: 20px; }
  #current_news .cover_bg .text,
  #current_news_content .date {
    font-size: 12px;
    padding: 5px 6px; }
  #current_news_content .date {
    top: 0;
    bottom: auto; }
  #master_brand h2 {
    font-size: 20px; }
  #video h2 {
    font-size: 26px; }
  #video .vid_iframe {
    width: 320px;
    height: 180px; }
  .formulaire input[type=email],
  .formulaire input[type=select],
  .formulaire input[type=tel],
  .formulaire input[type=text],
  .formulaire select {
    width: calc(100% - 15px); }
  #insurance .insurance_list,
  #paiement .paiement_list {
    column-count: 1; } }

.mp-level > ul > li > a > span {
  color: inherit; }

.mp-menu .mp-level li:last-child {
  display: block; }

.mp-menu ul li > a {
  padding-right: 0; }

#menu_launcher {
  top: 20px;
  bottom: inherit;
  left: 15px;
  display: none;
  width: 30px;
  height: 30px; }

#header.sticky #menu_launcher {
  top: 15px;
  left: 15px; }

#header.sticky #menu_launcher {
  top: 5px; }

.picto-menu {
  top: -5px; }

#logo_mh {
  margin: 0 auto; }

.mp-menu > .mp-level {
  top: 80px; }

.mp-menu > .mp-level.mp-level-open {
  height: calc(100% - 80px); }

.mobile_header {
  height: 80px;
  display: flex;
  align-items: center; }

@media (max-width: 990px) {
  #menu_launcher {
    display: block; }
  #logo_mh {
    width: 100%; }
  #logo_mh img {
    max-height: 60px; }
  #logo_mh .title {
    margin-left: 0; }
  .header-sup {
    order: 1; }
  #menu_launcher {
    position: static;
    margin: 0; }
  #sticky_header > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .scroller.mp-pushed {
    cursor: auto; }
  #mp-menu #mh_close {
    top: 12px;
    padding-top: 0;
    padding-right: 0; }
  #sticky_header .container > div {
    width: calc(100% / 3); }
  #sticky_header .header-sup {
    justify-content: center; }
  #sticky_header .container-mobile {
    order: 10; }
  .header-sup .col-right {
    position: absolute;
    right: 0; } }

@media (max-width: 767px) {
  #bloc_instagram_content .img_c {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 5px; } }

@media (max-width: 575px) {
  .list-post-card .col-left-post,
  .list-post-card .col-right-post {
    width: 100%;
    max-height: none;
    overflow-x: visible; }
  .list-post-card .post-card {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
    padding: 10px 5px; }
  #header #logo {
    position: absolute;
    left: 50px; }
  #bloc_instagram_content .instagram_content {
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2px; } }

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  src: local("Montserrat Light"), local("Montserrat-Light"), url(../fonts/montserrat-light.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(../fonts/montserrat-regular.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(../fonts/montserrat-medium.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url(../fonts/montserrat-bold.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Raleway';
  font-weight: 700;
  font-style: normal;
  src: local("Raleway Bold"), local("Raleway-Bold"), url(../fonts/Raleway-Bold.woff2) format("woff2");
  font-display: swap; }

.button {
  font-size: 12px;
  display: inline-block;
  padding: 13px 20px 12px 20px;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 5px; }

.button.btn1 {
  color: #fff;
  background: 0 0; }

.button.btn2 {
  color: #fff;
  background: rgba(74, 74, 92, 0.4); }

.button:hover {
  color: #a1bfd8;
  background: #fff; }

.button.btn3 {
  color: #a1bfd8;
  border-color: #a1bfd8;
  background: 0 0; }

.button.btn3:hover {
  color: #fff;
  background: #a1bfd8; }

.button.btn4 {
  color: #fa5957;
  border-color: #fa5957;
  background: 0 0; }

.button.btn4:hover {
  color: #fff;
  background: #fa5957; }

.btn-outline-white {
  display: inline-block;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 15px 20px; }

.btn-outline-black {
  display: inline-block;
  background-color: transparent;
  border: 1px solid #141414;
  color: #141414;
  padding: 15px 20px; }
  .btn-outline-black:hover {
    color: #000;
    background-color: #fff; }

.btn-outline {
  display: inline-block;
  padding: 18px 25px;
  border: 1px solid #000; }

.btn-demi-color2 {
  position: relative;
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  border: 2px solid #141414; }
  .btn-demi-color2 .texte {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1; }
    .btn-demi-color2 .texte .icon {
      display: inline-flex;
      font-size: 35px;
      margin-right: 40px; }
  .btn-demi-color2::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 85px;
    background-color: red;
    transition: all .3s; }
  .btn-demi-color2:hover .icon {
    color: #000; }
  .btn-demi-color2:hover::after {
    background-color: blue; }

body.color_v1 .btn-demi-color2::after {
  background-color: #F9E1DC; }

body.color_v1 .btn-demi-color2:hover::after {
  background-color: #ea9685; }

body.color_v1 .btn-hover-color1:hover {
  color: #fff;
  background-color: #EA624A; }

body.color_v2 .btn-demi-color2::after {
  background-color: #F0F3FD; }

body.color_v2 .btn-demi-color2:hover::after {
  background-color: #96abf1; }

body.color_v2 .btn-hover-color1:hover {
  color: #fff;
  background-color: #1A1F2B; }

body.color_v3 .btn-demi-color2::after {
  background-color: #E6EEFE; }

body.color_v3 .btn-demi-color2:hover::after {
  background-color: #84abfa; }

body.color_v3 .btn-hover-color1:hover {
  color: #fff;
  background-color: #0062F2; }

body.color_v4 .btn-demi-color2::after {
  background-color: #FFFDED; }

body.color_v4 .btn-demi-color2:hover::after {
  background-color: #fff287; }

body.color_v4 .btn-hover-color1:hover {
  color: #fff;
  background-color: #FEC14F; }

body.color_v5 .btn-demi-color2::after {
  background-color: #F6F6F6; }

body.color_v5 .btn-demi-color2:hover::after {
  background-color: #c3c3c3; }

body.color_v5 .btn-hover-color1:hover {
  color: #fff;
  background-color: #4646F4; }

body.color_v6 .btn-demi-color2::after {
  background-color: #FBF3E9; }

body.color_v6 .btn-demi-color2:hover::after {
  background-color: #ebc493; }

body.color_v6 .btn-hover-color1:hover {
  color: #fff;
  background-color: #D56846; }

body.color_v7 .btn-demi-color2::after {
  background-color: #EEF2E4; }

body.color_v7 .btn-demi-color2:hover::after {
  background-color: #c3d19f; }

body.color_v7 .btn-hover-color1:hover {
  color: #fff;
  background-color: #243526; }

body.color_v8 .btn-demi-color2::after {
  background-color: #EEE9FC; }

body.color_v8 .btn-demi-color2:hover::after {
  background-color: #a98ff0; }

body.color_v8 .btn-hover-color1:hover {
  color: #fff;
  background-color: #A48EF8; }

body.color_v9 .btn-demi-color2::after {
  background-color: #F9DEAD; }

body.color_v9 .btn-demi-color2:hover::after {
  background-color: #f2b84e; }

body.color_v9 .btn-hover-color1:hover {
  color: #fff;
  background-color: #DF5143; }

body.color_v10 .btn-demi-color2::after {
  background-color: #000000; }

body.color_v10 .btn-demi-color2:hover::after {
  background-color: black; }

body.color_v10 .btn-hover-color1:hover {
  color: #fff;
  background-color: #FFFFFF; }

.button_grey {
  display: inline-block;
  font-size: 11px;
  font-weight: 500;
  padding: 18px 36px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #4D4D4D;
  border: 1px solid #4D4D4D;
  border-radius: 30px;
  transition: all .3s ease-in-out;
  cursor: pointer; }
  .button_grey:active, .button_grey:hover {
    color: #4D4D4D;
    background-color: #fff; }

.h1,
.title,
h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 52px;
  font-weight: 800;
  margin: .5em 0;
  text-transform: uppercase;
  text-align: left;
  color: #141414; }

.h2,
.stitle,
h2 {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 36px;
  color: #141414;
  font-weight: 600; }

.h3,
.h3 a,
.sstitle,
.sstitle a,
h3,
h3 a {
  font-size: 18px;
  color: #555;
  font-weight: 700;
  margin: 15px 0;
  text-transform: uppercase; }

.h4,
.ssstitle,
h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 20px 0;
  color: #000;
  text-transform: none;
  text-align: center;
  line-height: 1.2; }

.h5 {
  font-size: 11px;
  font-weight: 400;
  margin-top: -.5em;
  text-align: left;
  color: #999; }

.title-subborder {
  text-align: left;
  border-bottom: .1rem solid #000;
  padding-bottom: 12px;
  margin-bottom: 25px; }

.title-lined {
  position: relative; }
  .title-lined::before {
    content: '';
    position: absolute;
    top: 1.5rem;
    left: 4.5rem;
    width: calc(100% - 9rem);
    height: .1rem;
    background-color: #000; }
  .title-lined span {
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding-left: 4rem;
    padding-right: 4rem; }

@media (max-width: 767px) {
  .h1, .title, h1 {
    font-size: 28px; }
  .h2, .stitle, h2 {
    font-size: 24px; } }

#acces {
  display: none; }

#header {
  height: 95px; }
  #header.alerte-fermeture-c {
    height: 135px; }
  #header .alerte-fermeture {
    text-align: center;
    color: #fff;
    padding-top: .6rem;
    padding-bottom: .7rem;
    font-size: 1.2rem; }

#header.sticky #sticky_header {
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 .3rem .4rem 0 #cfcfcf; }
  #header.sticky #sticky_header .main_menu {
    margin-top: 15px; }

.header-sup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95px; }
  .header-sup .col-left,
  .header-sup .col-right {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header-sup #logo img {
    max-width: 100px;
    max-height: 75px; }

.header-main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px; }
  .header-main li {
    margin-left: 15px;
    margin-right: 15px; }
    .header-main li a {
      font-family: 'Raleway', sans-serif;
      font-weight: 600;
      font-size: 16px; }

.mp-menu > .mp-level > ul .couleur3 {
  color: #777; }

.other-shop-block .other-shop {
  display: flex; }

.other-shop-block a {
  display: block;
  margin-bottom: 12px;
  text-decoration: underline;
  margin-left: 8px;
  margin-right: 8px; }
  .other-shop-block a:hover {
    color: #555; }

.other-shop-block .other-shop-title {
  display: block;
  margin-bottom: 12px;
  font-weight: 700; }

@media (max-width: 1499px) {
  .header-main {
    margin-left: 8px; }
    .header-main li {
      margin-left: 8px;
      margin-right: 8px; }
      .header-main li a {
        font-size: 14px; } }

@media (max-width: 991px) {
  .header-main {
    display: none; } }

@media (max-width: 575px) {
  #header .alerte-fermeture {
    font-size: .9rem;
    padding-top: .2rem;
    padding-bottom: .3rem; } }

#footer {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem; }

.main-footer {
  display: flex;
  justify-content: space-between; }
  .main-footer .h3 {
    font-size: 20px;
    font-weight: 700;
    color: #141414;
    margin-top: 0; }

.footer-nl {
  padding-top: 18px;
  padding-bottom: 18px; }
  .footer-nl .h4 {
    color: #141414;
    text-align: center;
    font-weight: 700;
    margin-top: 0; }
  .footer-nl form {
    display: flex;
    justify-content: center;
    align-items: center; }
    .footer-nl form input {
      width: calc(100% - 20px);
      max-width: 600px;
      height: 65px;
      padding-left: 40px;
      padding-right: 110px;
      border: none;
      font-size: 14px; }
    .footer-nl form button[type="submit"] {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -100px;
      cursor: pointer;
      border: none;
      height: 40px;
      padding-left: 18px;
      padding-right: 18px;
      transition: all .3s ease-in-out; }
      .footer-nl form button[type="submit"] .icon-arrow-right3 {
        font-size: 1rem;
        margin-left: 12px;
        color: #141414;
        line-height: 0; }
      .footer-nl form button[type="submit"]:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4); }
  .footer-nl #msg_newsletter {
    text-align: center;
    margin-top: 5px; }
  .footer-nl .message-rgpd {
    color: #000;
    max-width: 540px;
    margin: 5px auto 12px; }
    .footer-nl .message-rgpd label {
      cursor: pointer; }
      .footer-nl .message-rgpd label p {
        margin-bottom: 3px; }
  .footer-nl .rgpd-form {
    display: flex;
    align-items: flex-start; }
    .footer-nl .rgpd-form input {
      margin-top: 0;
      margin-right: 5px; }
  .footer-nl button[type="submit"].disabled {
    background-color: #bbb;
    cursor: not-allowed; }
    .footer-nl button[type="submit"].disabled:hover {
      box-shadow: none; }
  .footer-nl .see-more-rgpd a,
  .footer-nl .see-more-rgpd.lien-off {
    color: #000;
    font-size: 12px; }
    .footer-nl .see-more-rgpd a:hover,
    .footer-nl .see-more-rgpd.lien-off:hover {
      color: #555; }

#detail-rgpd {
  width: calc(100% - 40px);
  max-width: 600px;
  padding-bottom: 2px; }

.logo-menu-footer {
  display: flex; }
  .logo-menu-footer .logo-social-footer a, .logo-menu-footer .logo-social-footer .lien-off {
    display: block; }
  .logo-menu-footer .logo-social-footer .social-links-footer {
    display: flex; }
    .logo-menu-footer .logo-social-footer .social-links-footer a, .logo-menu-footer .logo-social-footer .social-links-footer .lien-off {
      font-size: 1.8rem;
      margin-top: .5rem;
      margin-right: .4rem; }
  .logo-menu-footer img {
    max-width: 200px;
    max-height: 250px; }

.footer-nav {
  margin-left: 25px;
  margin-top: 10px; }
  .footer-nav a, .footer-nav .lien-off {
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    margin-right: 5px; }
    .footer-nav a:hover, .footer-nav .lien-off:hover {
      color: #555; }

.infos-mag .infos-mag-block-adresse {
  margin-bottom: 20px; }
  .infos-mag .infos-mag-block-adresse .infos-adresse {
    margin-bottom: 10px;
    font-weight: 500; }
  .infos-mag .infos-mag-block-adresse .link-gstreet {
    display: block; }

.infos-mag .infos-mag-services > div {
  display: flex;
  align-items: center;
  margin-bottom: 8px; }

.infos-mag .infos-mag-services .circle-service {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  text-transform: uppercase;
  width: 32px;
  height: 32px;
  margin-left: 5px; }
  .infos-mag .infos-mag-services .circle-service .icon {
    font-size: 18px;
    line-height: 0; }

.infos-mag .infos-mag-services .texte {
  margin-left: 8px;
  font-size: 12px;
  text-transform: uppercase; }

.hours-now {
  text-align: right;
  margin-right: 40px;
  margin-bottom: 25px; }

.schedule_status .icon {
  margin-right: 5px; }

.schedule_status .text.ouvert {
  display: none; }

.schedule_status .color-ouvert,
.schedule_status .color-ferme {
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 6px; }

.schedule_status .color-ouvert {
  color: #36B843; }

.schedule_status .color-ferme {
  color: #fd1f1f; }

.schedule_status.open .text.ouvert {
  display: inline-block; }

.schedule_status.open .text.ferme {
  display: none; }

.infos-mag-block-horaires .btn-outline {
  margin-top: 12px; }

.infos-mag-block-horaires ul {
  min-width: 310px;
  margin-top: 0;
  padding-left: 0; }

.infos-mag-block-horaires li {
  display: flex;
  align-items: flex-start;
  align-items: center;
  margin-left: 0;
  line-height: .8;
  height: 38px; }
  .infos-mag-block-horaires li .day,
  .infos-mag-block-horaires li .hour,
  .infos-mag-block-horaires li .hour_center {
    display: inline-block;
    line-height: .8; }
  .infos-mag-block-horaires li .day {
    font-weight: 700;
    min-width: 100px;
    text-align: right;
    padding-right: 15px; }
  .infos-mag-block-horaires li .hour {
    min-width: 100px;
    font-weight: 500; }
    .infos-mag-block-horaires li .hour + .hour {
      margin-left: 20px; }
  .infos-mag-block-horaires li .hour-rdv {
    text-transform: uppercase;
    font-size: 7px;
    font-weight: 400; }
  .infos-mag-block-horaires li .hour_center {
    margin-right: auto;
    width: 130px; }

.sub-footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 30px;
  padding-top: 22px; }
  .sub-footer a, .sub-footer .lien-off {
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px; }
    .sub-footer a:hover, .sub-footer .lien-off:hover {
      color: #555; }

@media (max-width: 1300px) {
  .logo-menu-footer img {
    max-width: 120px;
    max-height: 160px; }
  .footer-nav {
    margin-left: 10px;
    margin-right: 10px; }
    .footer-nav a, .footer-nav .lien-off {
      font-size: 16px; }
  .infos-mag-block-horaires .btn-outline {
    font-size: 15px;
    padding: 15px 10px; }
  .infos-mag {
    font-size: 14px; }
  .hours-now {
    margin-right: 10px; }
  .infos-mag-block-horaires li .day {
    font-size: 15px;
    padding-right: 10px; } }

@media (max-width: 991px) {
  .main-footer {
    flex-wrap: wrap; }
    .main-footer .infos-mag-block-horaires {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 30px; }
  .sub-footer {
    flex-wrap: wrap;
    margin-top: 0;
    padding-top: 15px; }
    .sub-footer > div {
      margin-top: 10px; } }

@media (max-width: 767px) {
  .footer-nl .h4 {
    font-size: 16px; }
  .footer-nl form input {
    padding-left: 15px; } }

@media (max-width: 575px) {
  .logo-menu-footer {
    flex-wrap: wrap;
    justify-content: center; }
  .footer-nav {
    width: 100%;
    margin: 20px 0 10px; }
  .main-footer .infos-mag-block-horaires {
    flex-wrap: wrap;
    margin-top: 20px; }
    .main-footer .infos-mag-block-horaires .hours-now,
    .main-footer .infos-mag-block-horaires .hours-schedule {
      width: 100%; }
    .main-footer .infos-mag-block-horaires .hours-now {
      margin-bottom: 30px;
      text-align: center; } }

/* Section presentation */
.presentation {
  position: relative;
  padding-top: 55px;
  padding-bottom: 80px; }
  .presentation::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    background-image: url("../img/theme/theme6/wave.svg");
    background-size: cover;
    aspect-ratio: 1920 / 143;
    width: 100%;
    background-repeat: no-repeat;
    pointer-events: none; }
  .presentation .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .presentation .left-block {
    position: relative;
    width: calc(40% - 3rem);
    margin-right: auto; }
    .presentation .left-block .first-img {
      display: block;
      aspect-ratio: 446/589;
      max-width: 446px;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .presentation .left-block .second-img {
      position: relative;
      display: block;
      aspect-ratio: 296/391;
      max-width: 296px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: -60%;
      margin-left: -20%;
      z-index: 9; }
    .presentation .left-block .carre-blanc {
      position: absolute;
      top: 190px;
      left: -80px;
      width: 160px;
      height: 190px;
      background-color: #F2F2F2;
      opacity: .6;
      z-index: 10; }
  .presentation .right-block {
    width: calc(60% - 3rem); }
    .presentation .right-block .accroche {
      margin-top: -1rem;
      margin-bottom: 2rem; }
    .presentation .right-block .description {
      line-height: 1.3;
      margin-bottom: 3rem; }
    .presentation .right-block .btn-outline-black {
      margin-bottom: 3rem; }
  .presentation .other_title {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-bottom: .6rem;
    margin-top: 0;
    text-align: left; }
  .presentation .speciality ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px; }
    .presentation .speciality ul li {
      font-family: 'Raleway', sans-serif;
      font-size: 18px;
      margin-left: 0;
      line-height: 1.1; }

/* Section Services */
.services {
  max-width: 450px;
  padding-top: 80px;
  margin-left: auto;
  margin-right: auto; }
  .services.full-width {
    /* Cas où le bloc prend toute la largeur */
    display: flex;
    max-width: none; }
    .services.full-width .liste-services {
      flex-wrap: wrap;
      flex-direction: row; }
      .services.full-width .liste-services .block-services {
        width: calc(50% - 30px); }
  .services .liste-services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px; }
    .services .liste-services .block-services {
      margin-left: 15px;
      margin-right: 15px; }
  .services .btn-outline {
    margin-bottom: 20px;
    padding: 20px 25px; }

/* Section Offres */
.liste-offres {
  display: flex;
  justify-content: center; }

.card-offre {
  width: calc(25% - 30px);
  margin-left: 15px;
  margin-right: 15px; }
  .card-offre .img-offre {
    position: relative;
    margin-bottom: 15px; }
    .card-offre .img-offre img {
      aspect-ratio: 1;
      height: 100%;
      width: 100%;
      object-fit: cover; }
    .card-offre .img-offre .date-offre {
      position: absolute;
      top: 8px;
      right: 8px;
      color: #fff;
      padding: .6rem .4rem; }
  .card-offre .titre-offre {
    font-size: 20px;
    margin-bottom: 10px;
    height: 48px;
    overflow: hidden;
    line-height: 1.2; }
  .card-offre .texte-offre {
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.2;
    height: 80px;
    overflow: hidden;
    margin-bottom: 22px; }
  .card-offre .see-more-offre {
    text-align: right;
    margin-top: -20px;
    margin-bottom: 5px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
    transition: color .3s;
    cursor: pointer; }
    .card-offre .see-more-offre:hover {
      color: #000; }
  .card-offre .btn-outline {
    width: 100%;
    text-align: center; }

.news_block .news_title {
  text-transform: uppercase; }

section.offres .card-offre {
  width: calc(100% / 3 - 30px);
  margin-left: 15px;
  margin-right: 15px;
  transition: box-shadow ease-in-out .3s; }
  section.offres .card-offre:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.6); }
  section.offres .card-offre .img-offre {
    position: relative;
    display: block; }
    section.offres .card-offre .img-offre img {
      aspect-ratio: 390/291;
      height: 100%;
      width: 100%;
      object-fit: cover; }
    section.offres .card-offre .img-offre .date-offre {
      position: absolute;
      top: 8px;
      left: 8px;
      color: #000;
      font-weight: 700;
      font-size: 16px;
      padding: .6rem .4rem; }
  section.offres .card-offre .offre-content {
    padding: 15px 25px; }
  section.offres .card-offre .titre-offre {
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    height: 48px;
    overflow: hidden;
    line-height: 1.2; }
  section.offres .card-offre .texte-offre {
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.2;
    height: 78px;
    overflow: hidden;
    margin-bottom: 0; }
  section.offres .card-offre .see-more-offre {
    cursor: pointer;
    text-align: right;
    margin-top: .6rem;
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
    transition: color .3s; }
    section.offres .card-offre .see-more-offre:hover {
      color: #000; }
  section.offres .card-offre .btn-outline {
    width: 100%;
    text-align: center; }

section.offres .cta-offres {
  text-align: center;
  margin-top: 40px; }

/* Section Actualités */
.home-actus .h2 {
  margin-bottom: 30px; }

.home-actus .liste-actus-home {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px; }

.card-actu {
  width: calc(50% - 15px); }
  .card-actu.card-full-width {
    width: 100%;
    order: -1;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px; }
    .card-actu.card-full-width .desc {
      color: #141414; }
      .card-actu.card-full-width .desc .h4 {
        color: #141414; }
    .card-actu.card-full-width img {
      aspect-ratio: 1200/291; }
  .card-actu img {
    aspect-ratio: 500/275;
    width: 100%;
    object-fit: cover; }
  .card-actu .desc {
    padding: 16px 25px;
    line-height: 1.6;
    color: #fff;
    text-align: left; }
    .card-actu .desc .date {
      color: #141414;
      opacity: .6;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 2px; }
    .card-actu .desc .h4 {
      text-align: left;
      height: 50px;
      margin-top: 5px;
      margin-bottom: 10px;
      font-weight: 600;
      color: #fff;
      overflow: hidden;
      line-height: 1.2; }
    .card-actu .desc .desc-content {
      height: 78px;
      overflow: hidden; }

/* Section Test audition */
.test_audition_content_c {
  display: flex;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  height: auto;
  width: 100%;
  padding: .8rem;
  text-align: center;
  text-transform: uppercase;
  hyphens: none;
  color: #fff;
  aspect-ratio: 667/173; }
  .test_audition_content_c .btn-outline {
    font-size: 18px;
    font-weight: 700;
    display: inline-block;
    padding: 12px;
    text-align: center;
    text-transform: uppercase; }
    .test_audition_content_c .btn-outline:hover {
      background-color: rgba(0, 0, 0, 0.2); }
  .test_audition_content_c .shadow_text {
    text-shadow: rgba(0, 0, 0, 0.3) 2px 2px; }
  .test_audition_content_c .white_sep {
    max-width: 360px;
    height: 2px;
    margin: .7rem auto 1.2rem auto;
    background-color: #fff; }

/* Section avantages */
.bloc-avantages {
  overflow: hidden; }

.home-avantages {
  position: relative;
  padding-top: 80px;
  margin-bottom: 120px;
  max-width: 630px; }
  .home-avantages.full-width {
    max-width: none; }
  .home-avantages h2 {
    margin-top: 0; }

.bg-avantages {
  position: absolute;
  top: 0;
  right: -50vw;
  bottom: -80px;
  left: -4vw;
  z-index: -1; }

.liste-avantages {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-right: 10px; }
  .liste-avantages .avantage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: calc(50% - 20px);
    background-color: #fff;
    margin-bottom: 30px; }
    .liste-avantages .avantage .icon {
      display: block;
      font-size: 50px;
      margin-bottom: 10px; }
    .liste-avantages .avantage .texte {
      text-align: center;
      font-size: 20px;
      hyphens: none;
      line-height: 1.2;
      max-width: 200px; }

/* Section Vidéo */
#video.video-home .h2 {
  margin-bottom: 20px; }

#video.video-home .vid_iframe {
  width: 100%;
  max-width: 900px;
  height: auto;
  aspect-ratio: 1280 / 720; }

/* Section team */
.team-home .h2 {
  margin-bottom: 30px; }

.team-home .liste-team {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-left: -20px;
  margin-right: -20px; }

.card-team {
  position: relative;
  width: calc(100% / 3 - 110px);
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 40px; }
  .card-team .employee-img {
    position: absolute;
    top: 50%;
    left: -70px;
    transform: translateY(-50%); }
    .card-team .employee-img img {
      width: 100%;
      max-width: 170px;
      aspect-ratio: 200 / 260;
      object-fit: cover; }
  .card-team .employee {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 340px;
    padding-left: 110px;
    padding-right: 10px; }
  .card-team .employee_name {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    color: #141414; }
  .card-team .employee_status {
    text-transform: uppercase;
    font-weight: 500;
    color: #141414; }
  .card-team.no-image-team {
    width: calc(100% / 3 - 40px);
    margin-left: 20px;
    margin-right: 20px; }
    .card-team.no-image-team .employee {
      padding-left: 20px;
      padding-right: 20px; }

/* Section Marques */
.bandeau_marques {
  padding-top: 70px;
  padding-bottom: 150px; }
  .bandeau_marques .btn-outline {
    margin-top: 30px; }
  .bandeau_marques .slider_marques {
    margin-bottom: 0;
    padding-bottom: 0; }
    .bandeau_marques .slider_marques .slick-dots {
      right: 0;
      bottom: 1.5rem; }

/* Section label-certificat */
.certif-home {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px; }

.certif-home-content {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 16px 40px; }
  .certif-home-content .h2 {
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    color: #141414;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 30px; }

.liste-certifs {
  display: flex;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px; }
  .liste-certifs .certif {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% / 4 - 30px);
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    max-width: 320px; }
    .liste-certifs .certif a {
      width: 100%; }
    .liste-certifs .certif img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 120px;
      max-height: 120px; }

/* Section Avis */
#avis {
  padding-top: 50px;
  padding-bottom: 50px; }
  #avis .center-slide .slick-list {
    display: flex;
    justify-content: center; }
  #avis .slick-slide {
    margin-left: 6px;
    margin-right: 6px; }
  #avis .slick-prev::before,
  #avis .slick-next::before {
    display: block;
    text-align: center;
    line-height: 2.4;
    font-size: 20px; }
  #avis .slick-prev {
    left: -35px; }
  #avis .slick-next {
    right: -35px; }
  #avis .btn-outline {
    margin-top: 25px; }

.card-avis-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #141414;
  padding: 15px; }
  .card-avis-content .col-left-avis img {
    display: inline-block;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px; }
  .card-avis-content .col-right-avis {
    width: calc(100% - 85px); }
    .card-avis-content .col-right-avis .header-avis {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      text-transform: none; }
    .card-avis-content .col-right-avis .etoiles-avis {
      margin-top: 10px;
      margin-bottom: 5px; }
      .card-avis-content .col-right-avis .etoiles-avis .icon-favorite {
        color: #FFF743; }
  .card-avis-content .commentaire-avis {
    height: 40px;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    text-transform: none;
    line-height: 1.2;
    color: #141414; }
  .card-avis-content .cta-avis {
    text-align: right;
    height: 40px; }

.popin-view-all {
  margin-left: 20px;
  margin-right: 20px;
  padding: 0; }
  .popin-view-all .card-avis-content {
    min-width: 350px;
    max-width: 800px;
    border: none; }
    .popin-view-all .card-avis-content .commentaire-avis {
      height: auto; }
    .popin-view-all .card-avis-content.btn-demi-color2:hover::after {
      background-color: #F9E1DC; }

/* Section créations */
.creations .texte-creations {
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px; }

.creations .liste-creations {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; }
  .creations .liste-creations .card-creation {
    width: calc(25% - 40px);
    margin-left: 25px;
    margin-right: 15px;
    background-color: #fff; }
    .creations .liste-creations .card-creation .creation-img-c {
      position: relative;
      display: block;
      aspect-ratio: 250 / 375; }
      .creations .liste-creations .card-creation .creation-img-c .bg-shadow {
        position: absolute;
        top: -10px;
        left: -10px;
        width: 100%;
        height: 90%;
        z-index: -1;
        transition: all ease-in-out .3s; }
      .creations .liste-creations .card-creation .creation-img-c img {
        height: 100%;
        object-fit: cover;
        transition: transform .2s; }
    .creations .liste-creations .card-creation .h3 {
      padding: 10px 15px;
      font-size: 18px;
      font-weight: 500;
      color: #141414;
      text-align: center;
      text-transform: uppercase; }
    .creations .liste-creations .card-creation:hover .creation-img-c .bg-shadow {
      top: 0;
      left: 0; }

@media (max-width: 1499px) {
  .presentation .left-block {
    margin-right: 50px;
    margin-left: auto; } }

@media (max-width: 1300px) {
  .liste-avantages .avantage {
    width: calc(50% - 10px);
    margin-bottom: 10px;
    margin-left: 10px; }
  .bg-avantages {
    bottom: -40px;
    left: -2vw; }
  .home-avantages {
    padding-top: 30px; }
  .services {
    padding-top: 0; }
  .card-avis-content .col-right-avis .header-avis {
    align-items: flex-end;
    flex-direction: column;
    text-align: right; }
  .card-team .employee {
    padding-left: 40px;
    height: 220px; }
  .card-team .employee_name {
    font-size: 20px; }
  .card-team .employee_status {
    font-size: 14px; }
  .card-team .employee-img {
    left: -90px; }
    .card-team .employee-img img {
      max-width: 120px; } }

@media (max-width: 1199px) {
  .presentation .left-block {
    margin-right: 20px;
    margin-left: 80px; }
  .team-home .liste-team {
    flex-wrap: wrap; }
  .card-team {
    width: calc(100% / 2 - 130px);
    margin-left: 110px;
    margin-right: 20px; }
    .card-team.no-image-team {
      width: calc(100% / 2 - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px; }
  .card-avis-content .col-left-avis img {
    width: 52px;
    height: 52px;
    margin-right: 10px; }
  .card-avis-content .col-right-avis {
    width: calc(100% - 65px); }
  .creations .liste-creations .card-creation {
    width: calc(25% - 20px);
    margin-left: 10px;
    margin-right: 10px; } }

@media (max-width: 991px) {
  .presentation .left-block .carre-blanc {
    top: 210px;
    left: -70px;
    width: 140px;
    height: 140px; }
  .presentation .left-block .second-img {
    max-width: 165px;
    margin-top: -15%; }
  .bg-avantages {
    left: -50vw; }
  .home-avantages {
    margin-left: auto;
    margin-right: auto; }
  .card-offre {
    width: calc(100% / 3 - 10px);
    margin-left: 5px;
    margin-right: 5px; }
    .card-offre .offre-content {
      padding: 10px 15px; }
  .services .btn-demi-color2::after {
    width: 65px; }
  .services .btn-demi-color2 .texte {
    font-size: 14px; }
    .services .btn-demi-color2 .texte .icon {
      margin-right: 30px; }
  .services .btn-outline {
    padding: 20px 15px; }
  .liste-certifs {
    flex-wrap: wrap; }
    .liste-certifs .certif {
      width: calc(25% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px; } }

@media (max-width: 767px) {
  .presentation .container {
    flex-wrap: wrap; }
  .presentation .left-block {
    width: calc(60% - 3rem);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px; }
    .presentation .left-block .second-img {
      margin-left: -35%; }
    .presentation .left-block .carre-blanc {
      top: 300px;
      left: -40px; }
  .presentation .right-block {
    width: 100%; }
  .home-actus .liste-actus-home {
    padding-left: 0;
    padding-right: 0; }
  .card-actu.card-full-width {
    margin-bottom: 20px; }
  .card-actu {
    width: calc(50% - 10px); }
    .card-actu .desc {
      padding: 10px 15px; }
  .card-team {
    width: 100%;
    margin-bottom: 20px; }
    .card-team.no-image-team {
      width: 100%; }
    .card-team .employee_name {
      font-size: 24px; }
  .liste-offres {
    flex-wrap: wrap; }
    .liste-offres .card-offre {
      width: calc(50% - 10px);
      margin-bottom: 10px; }
  .bandeau_marques {
    padding-top: 30px;
    padding-bottom: 50px; }
  .creations .liste-creations {
    flex-wrap: wrap; }
    .creations .liste-creations .card-creation {
      width: calc(50% - 20px);
      margin-bottom: 20px; }
  .certif-home-content .h2 {
    font-size: 24px; } }

@media (max-width: 575px) {
  .presentation .left-block {
    width: 100%; }
    .presentation .left-block .second-img {
      margin-left: 60px; }
    .presentation .left-block .carre-blanc {
      top: auto;
      left: -20px;
      bottom: 120px; }
  .card-actu {
    width: 100%;
    margin-bottom: 10px; }
    .card-actu .desc {
      padding: 10px; }
    .card-actu.card-full-width {
      margin-bottom: 10px; }
  .employee_status {
    font-size: 14px; }
  .liste-certifs .certif {
    width: calc(50% - 20px); }
  .bloc-avantages-c {
    margin-left: 0;
    margin-right: 0; }
  .services .liste-services {
    margin-left: 0;
    margin-right: 0; }
  .services .btn-outline {
    padding: 15px 10px;
    margin-bottom: 10px; }
  .services .btn-demi-color2::after {
    width: 55px; }
  .services .btn-demi-color2 .texte .icon {
    margin-right: 20px; }
  .liste-avantages {
    margin-right: 0; }
    .liste-avantages .avantage {
      width: calc(100% - 10px);
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px; }
  .services.full-width .liste-services .block-services {
    width: calc(100% - 10px);
    margin-left: 5px;
    margin-right: 5px; }
  .liste-offres .card-offre {
    width: calc(100% - 10px); }
  .creations .liste-creations {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
    .creations .liste-creations .card-creation {
      width: calc(100% - 10px);
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px; }
  #bloc_instagram_content {
    margin-left: 0;
    margin-right: 0; }
  #avis .slick-dots {
    right: 0;
    bottom: -2.5rem; }
    #avis .slick-dots li {
      margin: 0 1px; }
      #avis .slick-dots li button {
        width: 1rem;
        height: 1rem; }
        #avis .slick-dots li button::before {
          width: 1rem;
          height: 1rem; }
  .team-home .liste-team {
    margin-left: 0;
    margin-right: 0; } }

/* Global */
body.color_v10 .btn-demi-color2::after {
  background-color: #000000; }

body.color_v10 .btn-demi-color2:hover .icon {
  color: #fff; }

body.color_v10 .btn-demi-color2:hover::after {
  background-color: black; }

body.color_v10 .btn-hover-color1:hover {
  background-color: #FFFFFF; }

html body.color_v10 {
  /* Homepage */
  /* Footer */ }
  html body.color_v10 .liste-offres a {
    color: #fff; }
  html body.color_v10 .liste-offres .texte-offre {
    color: #fff; }
  html body.color_v10 .infos-mag .infos-mag-services .circle-service {
    color: #000; }
  html body.color_v10 .nl_block .icon {
    transition: all .3s ease-in-out; }
  html body.color_v10 .nl_block .disabled .icon.icon-arrow-right3 {
    color: #6d6d6d; }
  html body.color_v10 .nl_block .couleur3_bg {
    background-color: #ccc; }
